import React from "react";
import Text from "../Text/Text";

interface DayBadgeProps {
  day: string;
  bgColor: string;
  textColor: string;
}

const DayBadge: React.FC<DayBadgeProps> = ({ day, bgColor, textColor }) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        padding: "4px 12px",
        borderRadius: "25px",
        textAlign: "center",
      }}
    >
      <Text
        fontFamily='IBM Plex Sans Thai'
        fontSize={14}
        fontWeight={600}
        style={{ color: textColor }}
      >
        {day}
      </Text>
    </div>
  );
};

export default DayBadge;
