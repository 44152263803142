import React, { ReactNode, useEffect, useState } from "react";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../store/RoleAtom";
import { CardContainer, OuterCardContainer } from "../../components/Card/CardContainer";
import { Col, Divider, Form, Modal, Row, Spin, Tag, Tooltip } from "antd";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import { requiredLabel } from "../../components/Form/FormItemComponents";
import DatePicker, { TimePicker } from "../../components/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import color from "../../resource/color";
import {
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  InfoCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import image from "../../resource/image";
import { FlexCol, FlexRow } from "../../components/Container/Container";
import AddProduct from "../Shared/AddProduct";
import { ModalSelectStore } from "../Shared/ModalSelectStore";
import { ProductEntity } from "../../entities/PoductEntity";
import { StoreEntity } from "../../entities/StoreEntity";
import Collapse from "../../components/Collapse/collapse";
import { CollapsePanelHeader, CollapsePanelItem } from "./QuotaComponents/ProductQuotaCollapse";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../datasource/ZoneDatasource";
import {
  getProductQuotaById,
  getProductQuotaProduct,
} from "../../datasource/ProductQuotaDatasource";
import PageSpin from "../../components/Spin/pageSpin";
import Descriptions from "../../components/Description/Descriptions";
import { MAP_STATUS_COLOR, MAP_STATUS_WORD } from "../../definitions/productQuota";

const AddProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 52px 50px;

  cursor: pointer;

  background: ${color["background1"]};
  border: 1px dashed ${color["primary"]};
  border-radius: 12px;
`;

const CollapseContainer = styled.div`
  .ant-collapse-content-box {
    background-color: ${color.background4} !important;
  }
`;

export const ProductQuotaDetail: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company } = userProfile;
  const navigate = useNavigate();

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[2];

  const [loading, setLoading] = useState(false);

  const [productList, setProductList] = useState<any[]>();

  const [data, setData] = useState<any>();
  const [zoneData, setZoneData] = useState<{ label: string; value: string; key: string }[]>([]);

  const [search, setSearch] = useState<string>("");

  useEffectOnce(() => {
    fetchData();

    if (zoneData.length <= 0) {
      getZoneByCompany();
    }
  });

  const fetchData = async () => {
    setLoading(true);
    const quotaData = await getProductQuotaById(id);

    const productQuota = await getProductQuotaProduct({
      quotaId: id,
      page: 1,
      take: 1000,
    });
    const newProductList = productQuota?.data?.map((p) => ({ ...p, stores: p.shopQuota }));
    setProductList(newProductList);
    setData({
      ...quotaData,
      productQuota,
    });
    setLoading(false);
  };

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZoneData(data);
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={"รายละเอียดโควตา"}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการกำหนดโควตาสินค้า", path: "/productQuota" },
              {
                text: "สร้างโควตา",
                path: window.location.pathname,
              },
            ]}
          />
        }
        extra={
          <Button
            title='แก้ไขโควตา'
            typeButton='primary'
            icon={<EditOutlined />}
            onClick={() => navigate("/productQuota/edit/" + id)}
          />
        }
      />
    );
  };

  const datePattern = "DD/MM/YYYY, HH:mm น.";
  const details = [
    {
      label: "ชื่อโควตา",
      value: data?.quotaName,
      span: 14,
    },
    {
      label: "อัพเดทโดย",
      value: data?.updatedBy,
      span: 10,
    },
    {
      label: "ระยะเวลาโควตา",
      value: (
        <>
          {dayjs(data?.startDate).format(datePattern)}&nbsp;-&nbsp;
          {dayjs(data?.endDate).format(datePattern)}
          {data?.daysPassed ? (
            <>
              <br />
              (ผ่านมาแล้ว&nbsp;{data?.daysPassed}&nbsp;วัน)
            </>
          ) : (
            ""
          )}
        </>
      ),
      span: 14,
    },
    {
      label: "อัพเดทล่าสุด",
      value: dayjs(data?.updatedAt).format(datePattern),
      span: 10,
    },
    {
      label: "สถานะ",
      value:
        data?.statusType !== "EXPIRED" ? (
          <Tag color={MAP_STATUS_COLOR[data?.statusType]}>
            <Text color='white' level={6} fontWeight={600}>
              {MAP_STATUS_WORD[data?.statusType]}
            </Text>
          </Tag>
        ) : (
          <Text color='placeholder'>{MAP_STATUS_WORD[data?.statusType]}</Text>
        ),
      span: 14,
      fullRow: true,
    },
    {
      label: "จำนวนร้านค้า",
      value: (
        <>
          {data?.countShop}&nbsp;ร้าน&nbsp;
          <Tooltip
            placement='top'
            title={
              <Text color='white'>
                ร้านค้าในแต่ละรายการสินค้า
                <br />
                ไม่นับรวมรายชื่อร้านที่ซ้ำกัน
              </Text>
            }
          >
            <InfoCircleFilled style={{ color: color.Text1, width: 14, height: 14 }} />
          </Tooltip>
        </>
      ),
      span: 14,
      fullRow: true,
    },
    {
      label: "จำนวนสินค้า",
      value: `${data?.countProduct} รายการ`,
      span: 14,
      fullRow: true,
    },
  ];

  return (
    <OuterCardContainer>
      <CardContainer cutBottom>
        <PageTitle />
      </CardContainer>
      <Divider />
      <CardContainer cutTop>
        {loading ? (
          <PageSpin />
        ) : (
          <>
            <Row>
              <Col span={24}>
                <Text level={4} fontWeight={700}>
                  รายละเอียดโควตา
                </Text>
              </Col>
              {details.map(({ label, value, span, fullRow }, i) => (
                <>
                  <Col span={span} key={`detail-${i}`} style={{ padding: "8px 0" }}>
                    <Descriptions label={label} value={value || "-"} noPadding noColon />
                  </Col>
                  {fullRow && <Col span={24 - span} />}
                </>
              ))}
            </Row>
            <Row align='bottom' justify='space-between' style={{ margin: "12px 0px" }}>
              <Col span={4}>
                <Input
                  placeholder='ค้นหารหัส/ชื่อร้าน'
                  suffix={<SearchOutlined />}
                  onChange={(e) => {
                    const value = (e.target as HTMLTextAreaElement).value;
                    setSearch(value);
                  }}
                  value={search}
                />
              </Col>
              <Col span={4}>
                <FlexRow justify='end'>
                  <Text>สินค้าทั้งหมด&nbsp;{productList?.length || 0}&nbsp;รายการ&nbsp;</Text>
                </FlexRow>
              </Col>
            </Row>
            <CollapseContainer>
              {productList
                ?.filter((item) => {
                  if (search && !item?.productName?.includes(search)) return false;
                  return true;
                })
                .map((item) => {
                  return (
                    <Collapse
                      key={item.productId}
                      defaultActiveKey={productList?.map((item) => item.productId)}
                      collapsible='icon'
                      expandIconPosition='end'
                      expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                    >
                      <Collapse.Panel
                        header={
                          <CollapsePanelHeader
                            item={item}
                            totalStore={item?.stores?.length || 0}
                            totalQuota={item?.stores?.reduce(
                              (acc, s) => acc + (s?.quotaReceive || 0),
                              0,
                            )}
                            totalUsedQuota={item?.stores?.reduce(
                              (acc, s) => acc + (s?.quotaUsed || 0),
                              0,
                            )}
                            editMode={false}
                            viewOnly={true}
                          />
                        }
                        key={item.productId}
                      >
                        <CollapsePanelItem
                          productQuotaId={item?.productQuotaId}
                          storeList={item?.stores || []}
                          zoneData={zoneData}
                          productId={item.productId}
                          editMode={false}
                          viewOnly={true}
                          avilability={[]}
                          onQuotaChange={undefined}
                        />
                      </Collapse.Panel>
                    </Collapse>
                  );
                })}
            </CollapseContainer>
          </>
        )}
        <Divider />
      </CardContainer>
    </OuterCardContainer>
  );
};
