import { Texts } from "../OrderPeriod.styles";
import { Col, Row, Tooltip } from "antd";
import { InfoCircleFilled, SearchOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import Input from "../../../../components/Input/Input";
import Select from "../../../../components/Select/Select";
import Button from "../../../../components/Button/Button";
import StatusBadge from "../../../../components/Badge/StatusBadge";
import { color, icon, image } from "../../../../resource";
import TablePagination from "../../../../components/Table/TablePagination";
import Text from "../../../../components/Text/Text";
import DayBadge from "../../../../components/Badge/DayBadge";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";
import ManageSpecialShop from "../Modal/ManageSpecialShop";
import { MAP_DAYS } from "../../../../definitions/dateInfo";
import { deleteOrderTimeShop, getOrderTime } from "../../../../datasource/OrderTimeDatasource";
import {
  AllOrderTimeEntity_INIT,
  type AllOrderTimeEntity,
} from "../../../../entities/OrderTimeEntity";
import moment from "moment";
import HowtoSetting, { getDescription } from "../Modal/HowtoSetting";

function SpecialShop({ isRefresh }: { isRefresh: number }) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [type, setType] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const [edit, setEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [shopName, setShopName] = useState<string>("");
  const [modalAddShop, setModalAddShop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AllOrderTimeEntity>(AllOrderTimeEntity_INIT);
  const [shopOrderId, setShopOrderId] = useState<string>("");
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [modalHowto, setModalHowto] = useState(false);

  useEffect(() => {
    fetchDataOrderTime();
  }, [isRefresh]);
  useEffect(() => {
    fetchDataOrderTime();
  }, [searchText, page, status, type]);

  const fetchDataOrderTime = async () => {
    setLoading(true);
    try {
      await getOrderTime(page, 10, userProfile?.company, searchText, status, type).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const infoDate = [
    { day: "เต็มวัน", bgColor: color.primary, textColor: color.white },
    { day: "ไม่เต็มวัน", bgColor: color.lightPrimary, textColor: "#4C95FF" },
    { day: "หยุด (ไม่สามารถสั่งซื้อได้)", bgColor: color.background4, textColor: color.white },
  ];

  const handleDeleteRow = (id: string, day: string) => {
    setModalDelete(true);
    setShopName(day);
    setShopOrderId(id);
  };
  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteOrderTimeShop(shopOrderId).then(() => {
        setModalDelete(false);
        fetchDataOrderTime();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: any = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "dealerCode",
      key: "dealerCode",
      render: (value: any) => <Texts size='16'>{value}</Texts>,
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "dealerName",
      key: "dealerName",
      render: (value: any, row: any) => (
        <>
          <Texts size='16'>{value}</Texts>
          <Texts color={color.Text3} size='14px'>
            {row.dealerNo}
          </Texts>
        </>
      ),
    },
    {
      title: "ช่วงเวลาคำสั่งซื้อ",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (value: any, row: any) => (
        <Row gutter={[8, 8]}>
          {row.data.map((day, idx) => {
            const bgColor = day?.fullDay
              ? color.primary
              : day?.isClose
                ? color.background4
                : color.lightPrimary;
            const textColor = bgColor === color.lightPrimary ? "#4C95FF" : color.white;
            const time = day?.times;
            return (
              <Col key={idx}>
                <Tooltip
                  style={{ width: "15%" }}
                  title={`${day.day} ${
                    day?.isClose
                      ? "หยุด (ไม่สามารถสั่งซื้อได้)"
                      : time?.length > 1
                        ? `${moment(time[0]?.start).format("HH:mm")}-${moment(time[0]?.end).format("HH:mm")} และ ${moment(time[1]?.start).format("HH:mm")}-${moment(time[1]?.end).format("HH:mm")}`
                        : `${moment(time[0]?.start).format("HH:mm")}-${moment(time[0]?.end).format("HH:mm")}`
                  } `}
                  placement='top'
                >
                  <span>
                    <DayBadge day={MAP_DAYS[day.day]} bgColor={bgColor} textColor={textColor} />
                  </span>
                </Tooltip>
              </Col>
            );
          })}
        </Row>
      ),
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any) => (
        <StatusBadge
          label={value === "ACTIVE" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
          bgColor={value === "ACTIVE" ? color.success : color.Text3}
        />
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      width: "12%",
      render: (value: any, row: any, index: number) => (
        <Row justify='start' gutter={6}>
          <Col span={10}>
            <Button
              onClick={() => {
                setModalAddShop(true);
                setEdit(true);
                setShopOrderId(row.id);
              }}
              typeButton='secondary'
              icon={<img src={icon.iconEdit} style={{ width: "18px", height: "18px" }} />}
            />
          </Col>
          <Col span={10}>
            <Button
              onClick={() => handleDeleteRow(row.id, row.dealerName)}
              typeButton='secondary'
              icon={<img src={icon.delete} style={{ width: "16px", height: "18px" }} />}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 22,
        }}
      >
        {data.latestUpdate && (
          <Texts>
            อัปเดทล่าสุดเมื่อ {moment(data.latestUpdate.updatedAt).format("DD/MM/YYYY, HH:mm")}{" "}
            โดย&nbsp;
            {data.latestUpdate.updatedBy}
          </Texts>
        )}
        <u style={{ display: "flex", color: color.primary }} onClick={() => setModalHowto(true)}>
          <InfoCircleFilled style={{ color: color.primary, marginRight: 4 }} />
          <Texts style={{ color: color.primary, fontWeight: 700, cursor: "pointer" }}>
            อ่านวิธีการใช้งานเบื้องต้น
          </Texts>
        </u>
      </div>
      <div style={{ marginTop: "22px" }}>
        <Row justify='space-between' gutter={{ xs: 8 }}>
          <Col className='gutter-row' span={11}>
            <Input
              placeholder='รหัสร้านค้า / ชื่อร้าน / เลขนิติบุคคล'
              suffix={<SearchOutlined />}
              defaultValue={searchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearchText(value);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchText(undefined);
                  setPage(1);
                }
              }}
            />
          </Col>
          <Col className='gutter-row' span={6}>
            <Select
              allowClear
              placeholder='ช่วงเวลาคำสั่งซื้อทั้งหมด'
              data={[
                { key: "ALL", value: "ALL", label: "ทั้งหมด" },
                { key: "FULLDAY", value: "FULLDAY", label: "เต็มวัน" },
                { key: "SOMETIME", value: "SOMETIME", label: "ไม่เต็มวัน" },
                { key: "CLOSE", value: "CLOSE", label: "หยุด (ไม่สามารถสั่งซื้อได้)" },
              ]}
              value={type}
              style={{ width: "100%" }}
              onChange={setType}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Select
              value={status}
              allowClear
              placeholder='สถานะ'
              data={[
                { key: "ACTIVE", value: "ACTIVE", label: "เปิดใช้งาน" },
                { key: "INACTIVE", value: "INACTIVE", label: "ปิดใช้งาน" },
              ]}
              style={{ width: "100%", fontFamily: "Sarabun" }}
              onChange={setStatus}
            />
          </Col>
          <Col className='gutter-row' span={2}>
            <Button
              typeButton='primary'
              title='+ เพิ่มร้าน'
              height={40}
              onClick={() => {
                setModalAddShop(true);
                setEdit(false);
              }}
            />
          </Col>
        </Row>
      </div>
      <Row gutter={[8, 8]} justify='end' style={{ marginBottom: "22px" }}>
        {infoDate.map((item, index) => (
          <Col key={index} style={{ marginTop: "22px" }}>
            <DayBadge day={item.day} bgColor={item.bgColor} textColor={item.textColor} />
          </Col>
        ))}
      </Row>
      <TablePagination
        isLoading={loading}
        columns={columns}
        data={data.data}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 32,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 24,
                minHeight: 400,
              }}
            >
              <img
                src={image.emptyShopTable}
                style={{
                  width: 170,
                  height: 110,
                }}
                alt='empty_table'
              />
              <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
                ยังไม่พบข้อมูล
              </Text>
            </div>
          ),
        }}
        pagination={{
          current: page,
          total: data?.count || 0,
          pageSize: 10,
        }}
        onPageChange={(pageNum) => setPage(pageNum)}
      />

      <ConfirmModal
        visible={modalDelete}
        onConfirm={handleDeleteConfirm}
        onCancel={() => {
          setModalDelete(false);
        }}
        title='ต้องการลบร้านค้าพิเศษใช่หรือไม่?'
        desc={`โปรดยืนยันการลบร้านค้าพิเศษ “${shopName}”`}
      />
      <ManageSpecialShop
        isEdit={edit}
        visible={modalAddShop}
        onCancel={() => setModalAddShop(false)}
        shopOrderId={shopOrderId}
        newFetch={() => {
          window.location.reload();
          fetchDataOrderTime();
        }}
      />
      <HowtoSetting
        visible={modalHowto}
        onCancel={() => setModalHowto(false)}
        title='เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน)'
        desc={getDescription("เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน)")}
      />
    </>
  );
}

export default SpecialShop;
