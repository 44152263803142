import React from "react";
import color from "../../resource/color";

export const ActionBtn = ({ onClick, icon, disabled }: any) => {
  return (
    <div
      className='btn btn-icon btn-light btn-hover-primary btn-sm'
      onClick={onClick}
      style={disabled ? { cursor: "not-allowed" } : {}}
    >
      <span className='svg-icon svg-icon-primary svg-icon-2x' style={{ color: color["primary"] }}>
        {icon}
      </span>
    </div>
  );
};

export const ActionBtnWhite = ({ onClick, icon }: any) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: color.white,
        width: 32,
        height: 32,
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
    >
      <span className='svg-icon svg-icon-primary svg-icon-2x'>{icon}</span>
    </div>
  );
};
