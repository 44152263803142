export interface TimeEntity {
  end: string;
  start: string;
}
export const TimeEntity_INIT: TimeEntity = {
  end: "",
  start: "",
};
export interface DayListDataEntity {
  day: string;
  fullDay: boolean;
  isClose: boolean;
  times: TimeEntity[];
}
export const DayListDataEntity_INIT: DayListDataEntity = {
  day: "",
  fullDay: false,
  isClose: false,
  times: [TimeEntity_INIT],
};
export interface OrderTimeMasterEntity {
  data: DayListDataEntity[];
  id: string;
  updatedAt: string;
  updatedBy: string;
}
export const OrderTimeMasterEntity_INIT : OrderTimeMasterEntity = {
  data: [DayListDataEntity_INIT],
  id: "",
  updatedAt: "",
  updatedBy: "",
}

export interface HolidaysEntity {
  endDate: string;
  holidayName: string;
  holidaysType: string;
  id: string;
  startDate: string;
  status: string;
  updatedAt: string;
  updatedBy: string;
}
export const HolidaysEntity_INIT: HolidaysEntity = {
  endDate: "",
  holidayName: "",
  holidaysType: "",
  id: "",
  startDate: "",
  status: "",
  updatedAt: "",
  updatedBy: "",
};
export interface AllHolidaysEntity {
  data: HolidaysEntity[];
  count: number;
  latestUpdate: {
    updatedAt: string;
    updatedBy: string;
  };
}
export const AllHolidaysEntity_INIT: AllHolidaysEntity = {
  data: [HolidaysEntity_INIT],
  count: 0,
  latestUpdate: {
    updatedAt: "",
    updatedBy: "",
  },
};

export interface OrderTimeEntity {
  createdAt: string;
  createdBy: string;
  data: DayListDataEntity[];
  dealerCode: string;
  dealerId: number;
  dealerName: string;
  dealerNo: string;
  fullDay: boolean;
  id: string;
  isClose: boolean;
  status: string;
  updatedAt: string;
  updatedBy: string;
}
export const OrderTimeEntity_INIT: OrderTimeEntity = {
  createdAt: "",
  createdBy: "",
  data: [DayListDataEntity_INIT],
  dealerCode: "",
  dealerId: 0,
  dealerName: "",
  dealerNo: "",
  fullDay: false,
  id: "",
  isClose: false,
  status: "",
  updatedAt: "",
  updatedBy: "",
};
export interface AllOrderTimeEntity {
  data: OrderTimeEntity[];
  count: number;
  latestUpdate: {
    updatedAt: string;
    updatedBy: string;
  };
}
export const AllOrderTimeEntity_INIT: AllOrderTimeEntity = {
  data: [OrderTimeEntity_INIT],
  count: 0,
  latestUpdate: {
    updatedAt: "",
    updatedBy: "",
  },
};
