import { SearchOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Badge, Col, Modal, Row, Switch, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { CardContainer } from "../../components/Card/CardContainer";
import { RangePicker } from "../../components/DatePicker/DatePicker";
import Text from "../../components/Text/Text";
import { FlexCol } from "../../components/Container/Container";
import Button from "../../components/Button/Button";
import { color, icon, image } from "../../resource";
import {
  changeStatusProductQuota,
  deleteProductQuota,
  getProductQuotaList,
} from "../../datasource/ProductQuotaDatasource";
import { dateFormatter } from "../../utility/Formatter";
import SortFieldCustom from "../../components/SortFieldTable/SortFieldCustom";
import { useNavigate } from "react-router-dom";
import { checkPermission } from "../../components/Permission/Permission";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../store/RoleAtom";

const REQUEST_DMY = "YYYY-MM-DD";

export const IndexProductQuota: React.FC = () => {
  const navigate = useNavigate();
  const roleData = useRecoilValue(roleAtom);
  const pageSize = 8;
  const [page, setPage] = useState<number>(1);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [keyword, setKeyword] = useState<string>();
  const [dateFilter, setDateFilter] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<any>("All");
  const [sortField, setSortField] = useState<string | undefined>("updatedAt");
  const [sortDirection, setSortDirection] = useState<string | undefined>(undefined);
  const [data, setData] = useState<{
    count: number;
    data: any[];
    statusCount: {
      ACTIVE: number;
      ALL: number;
      EXPIRED: number;
      INACTIVE: number;
      WAITTING: number;
    };
  }>({
    count: 0,
    data: [],
    statusCount: {
      ACTIVE: 0,
      ALL: 0,
      EXPIRED: 0,
      INACTIVE: 0,
      WAITTING: 0,
    },
  });
  const [modalType, setModalType] = useState<string>();
  const [status, setStatus] = useState<boolean>(false);
  const [quotaId, setQuotaId] = useState<any>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [warningModal, setWarningModal] = useState<boolean>(false);

  const getProdQuotaList = async () => {
    await getProductQuotaList({
      company: userProfile?.company,
      status: selectedTab,
      search: keyword,
      page: page,
      take: pageSize,
      startDate: dateFilter && dateFilter[0] ? dateFilter[0].format(REQUEST_DMY) : undefined,
      endDate: dateFilter && dateFilter[1] ? dateFilter[1].format(REQUEST_DMY) : undefined,
      sortBy: sortDirection ? sortField : undefined,
      sortDirection: sortDirection,
    }).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    getProdQuotaList();
  }, [selectedTab, keyword, page, dateFilter, sortField, sortDirection]);

  const ActionBtn = ({ onClick, icon }: any) => {
    return (
      <Col span={6}>
        <div className='btn btn-icon btn-light btn-hover-primary btn-sm' onClick={onClick}>
          <span
            className='svg-icon svg-icon-primary svg-icon-2x'
            style={{ color: color["primary"] }}
          >
            {icon}
          </span>
        </div>
      </Col>
    );
  };

  const PageTitle = () => {
    return (
      <Row align='middle' gutter={16}>
        <Col className='gutter-row' span={9}>
          <div>
            <span
              className='card-label font-weight-bolder text-dark'
              style={{ fontSize: 20, fontWeight: "bold" }}
            >
              รายการโควตาสินค้า
            </span>
          </div>
        </Col>
        <Col span={5}>
          <Input
            allowClear
            placeholder='ค้นหาชื่อโควตา'
            suffix={<SearchOutlined />}
            defaultValue={keyword}
            onPressEnter={(e) => {
              const value = (e.target as HTMLTextAreaElement).value;
              setKeyword(value);
            }}
            onChange={(e) => {
              const value = (e.target as HTMLInputElement).value;
              if (!value) {
                setKeyword("");
              }
            }}
          />
        </Col>
        <Col span={7}>
          <RangePicker
            allowEmpty={[true, true]}
            enablePast
            value={dateFilter}
            onChange={(dates, dateString) => {
              setDateFilter(dates);
            }}
          />
        </Col>
        <Col>
          <Button
            type='primary'
            title='+ สร้างโควตา'
            height={40}
            onClick={() => navigate("/productQuota/create")}
            size='large'
            disabled={!checkPermission(["productQuota", "create"], roleData)}
          />
        </Col>
      </Row>
    );
  };

  const tabsItems = [
    {
      label: `ทั้งหมด (${data?.statusCount["ALL"]})`,
      key: "ALL",
    },
    {
      label: `รอเริ่ม (${data?.statusCount["WAITING"]})`,
      key: "WAITING",
    },
    {
      label: `เปิดใช้งาน (${data?.statusCount["ACTIVE"]})`,
      key: "ACTIVE",
    },
    {
      label: `ปิดใช้งาน (${data?.statusCount["INACTIVE"]})`,
      key: "INACTIVE",
    },
    {
      label: `หมดอายุ (${data?.statusCount["EXPIRED"]})`,
      key: "EXPIRED",
    },
  ];

  const handleSortChange = (field: string) => {
    setSortField((prevSortField) => {
      if (prevSortField === field) {
        setSortDirection((prevDirection) => {
          if (prevDirection === "ASC") return "DESC";
          if (prevDirection === "DESC") return undefined;
          return "ASC";
        });
      } else {
        setSortField(field);
        setSortDirection("ASC");
      }
      return field;
    });
  };

  const columns = [
    {
      title: "ชื่อโควตา",
      dataIndex: "quotaName",
      key: "quotaName",
      width: "25%",
    },
    {
      title: "ระยะเวลาการใช้งานโควตา",
      dataIndex: "startDate",
      key: "startDate",
      width: "20%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>
                {dateFormatter(row.startDate)} - {dateFormatter(row.endDate)}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: (
        <SortFieldCustom
          title='ร้านค้าที่ให้โควตา'
          sortDirection={sortField === "countShop" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("countShop")}
        />
      ),
      dataIndex: "countShop",
      key: "countShop",

      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                ร้าน
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: (
        <SortFieldCustom
          title='จำนวนสินค้า'
          sortDirection={sortField === "countProduct" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("countProduct")}
        />
      ),
      dataIndex: "countProduct",
      key: "countProduct",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text>{value}</Text>
              <Text level={6} color='Text3'>
                รายการ
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: (
        <SortFieldCustom
          title='อัปเดตโดย'
          sortDirection={sortField === "updatedAt" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("updatedAt")}
        />
      ),
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <FlexCol>
              <Text> {dateFormatter(row.updatedAt)}</Text>
              <Text level={6} color='Text3'>
                {value}
              </Text>
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        const checked = row.status === "ACTIVE" ? true : false;
        return {
          children: (
            <FlexCol>
              {row.statusType === "EXPIRED" ? (
                <Text color='Text3' level={6}>
                  หมดอายุ
                </Text>
              ) : (
                <>
                  {row.statusType === "WAITING" && (
                    <div style={{ paddingBottom: "4px" }}>
                      <Badge
                        count='รอเริ่ม'
                        style={{
                          backgroundColor: color.secondary2,
                          fontFamily: "IBM Plex Sans Thai",
                        }}
                      />
                    </div>
                  )}
                  <Switch
                    key={row.quotaId}
                    checked={checked}
                    style={{
                      width: "85px",
                    }}
                    checkedChildren={checked ? "เปิดการใช้งาน" : ""}
                    unCheckedChildren='ปิดใช้งาน'
                    onChange={async (e) => {
                      setQuotaId(row.quotaId);
                      setStatus(e);
                      setModalType("status");
                      setShowConfirm(!showConfirm);
                    }}
                  />
                </>
              )}
            </FlexCol>
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      width: "10%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row gutter={40} justify='start'>
              {checkPermission(["productQuota", "edit"], roleData) && (
                <ActionBtn
                  onClick={() => navigate("/productQuota/" + row.quotaId)}
                  icon={
                    <UnorderedListOutlined style={{ width: 16, height: 16, fontWeight: 700 }} />
                  }
                />
              )}
              {(row.statusType === "WAITING" ||
                (row.status === "INACTIVE" && row.statusType !== "EXPIRED")) && (
                <ActionBtn
                  icon={
                    <img
                      src={icon.delete}
                      style={{ width: 16, height: 16 }}
                      onClick={() => {
                        setQuotaId(row.quotaId);
                        setModalType("delete");
                        setShowConfirm(!showConfirm);
                        //setWarningModal(true);
                      }}
                    />
                  }
                />
              )}
            </Row>
          ),
        };
      },
    },
  ];

  const changeStatus = async (quotaId: string, checked: boolean) => {
    const status = checked ? "ACTIVE" : "INACTIVE";
    const name = `${userProfile.firstname} ${userProfile.lastname}`;
    await changeStatusProductQuota({
      quotaId: quotaId,
      status,
      updateBy: name,
    }).then((res) => {
      if (res.success) {
        setQuotaId(undefined);
        setShowConfirm(false);
        getProdQuotaList();
      }
    });
  };
  const deleteQuota = async (quotaId: string) => {
    const name = `${userProfile.firstname} ${userProfile.lastname}`;
    await deleteProductQuota({
      quotaId: quotaId,
      updateBy: name,
    }).then((res) => {
      setShowConfirm(false);
      if (res.success) {
        setQuotaId(undefined);
        getProdQuotaList();
      } else {
        if (res.developerMessage.includes("delete")) {
          setWarningModal(true);
        }
      }
    });
  };

  return (
    <>
      <CardContainer>
        <PageTitle />
        <br />
        <Tabs
          items={tabsItems}
          onChange={(key: string) => {
            setSelectedTab(key);
          }}
          defaultValue={"ALL"}
        />
        <Table
          className='rounded-lg'
          columns={columns}
          dataSource={data?.data || []}
          pagination={{
            pageSize,
            current: page,
            total: data?.count,
            showSizeChanger: false,
            onChange: (page) => setPage(page),
            position: ["bottomCenter"],
          }}
          size='large'
          tableLayout='fixed'
        />
      </CardContainer>
      {showConfirm && (
        <Modal
          open={showConfirm}
          onCancel={() => setShowConfirm(!showConfirm)}
          title={
            modalType === "status" ? (
              <Text level={3} fontWeight={700}>
                ยืนยันการเปลี่ยนสถานะเป็น {status ? "''เปิดใช้งาน''" : "''ปิดใช้งาน''"}
              </Text>
            ) : (
              <Text level={3} fontWeight={700}>
                ต้องการยืนยันการลบรายการโควตาสินค้า
              </Text>
            )
          }
          centered
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              {modalType === "delete" && (
                <Row justify={"end"} gutter={8}>
                  <Col>
                    <Button
                      title='ยกเลิก'
                      typeButton='border-danger'
                      onClick={() => setShowConfirm(!showConfirm)}
                    />
                  </Col>
                  <Col>
                    <Button
                      title='ยืนยัน'
                      typeButton='danger'
                      onClick={() => deleteQuota(quotaId)}
                    />
                  </Col>
                </Row>
              )}
              {modalType === "status" && (
                <Row justify={"end"} gutter={8}>
                  <Col>
                    <Button
                      title='ยกเลิก'
                      typeButton='primary-light'
                      onClick={() => setShowConfirm(!showConfirm)}
                    />
                  </Col>
                  <Col>
                    <Button
                      title='ยืนยัน'
                      typeButton='primary'
                      onClick={() => changeStatus(quotaId, status)}
                    />
                  </Col>
                </Row>
              )}
            </>
          )}
        >
          <Text color='Text3'>
            โปรดตรวจสอบรายละเอียดอีกครั้ง ก่อนการกดยืนยัน{modalType === "delete" && "การลบ"}
          </Text>
        </Modal>
      )}
      {warningModal && (
        <Modal
          width={350}
          centered
          open={warningModal}
          closable={false}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <Row gutter={8}>
                <Button
                  title='เข้าใจแล้ว'
                  typeButton='primary'
                  onClick={() => {
                    setWarningModal(!warningModal);
                    getProdQuotaList();
                  }}
                />
              </Row>
            </>
          )}
        >
          <Row justify={"center"} style={{ padding: "8px" }}>
            <img src={image.warning_product_quota} />
          </Row>
          <Row justify={"center"}>
            <Text level={4} fontWeight={700}>
              ไม่สามารถลบรายการโควตาสินค้าได้
            </Text>
            <Text color='Text3'>เนื่องจากรายการโควตานี้เข้าสู่วันเริ่มต้น</Text>
          </Row>
          <Row justify={"center"}>
            <Text color='Text3'>ใช้งานโควตาในระบบแล้ว</Text>
          </Row>
        </Modal>
      )}
    </>
  );
};
