import React, { useState, useEffect, type JSX } from "react";
import { CardContainer } from "../../../components/Card/CardContainer";
import { Col, Row, Spin, Tabs } from "antd";
import Text from "../../../components/Text/Text";
import OrderPeriod from "./Tabs/OrderPeriod";
import SpecialShop from "./Tabs/SpecialShop";
import SpecialHoliday from "./Tabs/SpecialHoliday";

function TimeOrders() {
  const DEFAULT_TAB = "ORDER_TIME";
  const [activeTabKey, setActiveTabKey] = useState<string>(
    localStorage.getItem("activeTabKey") || DEFAULT_TAB,
  );
  const [refreshKey, setRefreshKey] = useState<number>(0);
  const handleRefresh = () => {
    setRefreshKey((prev) => prev + 1);
  };

  useEffect(() => {
    localStorage.setItem("activeTabKey", activeTabKey);
  }, [activeTabKey]);

  const tabsData: { key: string; label: React.ReactNode; children?: JSX.Element | undefined }[] = [
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          เวลาเปิด-ปิดการสั่งซื้อ (ร้านทั้งหมด)
        </Text>
      ),
      key: "ORDER_TIME",
      children: <OrderPeriod isRefresh={refreshKey} />,
    },
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน)
        </Text>
      ),
      key: "SPECIAL_DAY",
      children: <SpecialShop isRefresh={refreshKey} />,
    },
    {
      label: (
        <Text fontSize={14} fontWeight={700}>
          กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ)
        </Text>
      ),
      key: "SPECIAL_SHOP",
      children: <SpecialHoliday isRefresh={refreshKey} />,
    },
  ];

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <CardContainer>
      <Row align='middle' gutter={16} style={{ marginBottom: 32 }}>
        <Col span={20}>
          <Text level={3} fontWeight={700} color='Text2'>
            เวลาเปิด/ปิดคำสั่งซื้อ
          </Text>
        </Col>
      </Row>
      <Tabs
        items={tabsData}
        activeKey={activeTabKey}
        onChange={(e) => {
          handleTabChange(e);
          handleRefresh();
        }}
      />
    </CardContainer>
  );
}

export default TimeOrders;
