import React, { ReactNode, useEffect, useState } from "react";
import PageTitleNested from "../../components/PageTitle/PageTitleNested";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { roleAtom } from "../../store/RoleAtom";
import { CardContainer, OuterCardContainer } from "../../components/Card/CardContainer";
import { Col, Divider, Form, Modal, Row, Spin } from "antd";
import Text from "../../components/Text/Text";
import Input from "../../components/Input/Input";
import { requiredLabel } from "../../components/Form/FormItemComponents";
import DatePicker, { TimePicker } from "../../components/DatePicker/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import color from "../../resource/color";
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import image from "../../resource/image";
import { FlexCol, FlexRow } from "../../components/Container/Container";
import AddProduct from "../Shared/AddProduct";
import { ModalSelectStore } from "../Shared/ModalSelectStore";
import { ProductEntity } from "../../entities/PoductEntity";
import { StoreEntity } from "../../entities/StoreEntity";
import Collapse from "../../components/Collapse/collapse";
import { CollapsePanelHeader, CollapsePanelItem } from "./QuotaComponents/ProductQuotaCollapse";
import { useEffectOnce } from "react-use";
import { zoneDatasource } from "../../datasource/ZoneDatasource";
import {
  checkProductShop,
  createProductQuota,
  deleteQuotaProductQuota,
  getProductQuotaById,
  getProductQuotaList,
  getProductQuotaProduct,
  updateProductQuota,
} from "../../datasource/ProductQuotaDatasource";
import PageSpin from "../../components/Spin/pageSpin";

const AddProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 52px 50px;

  cursor: pointer;

  background: ${color["background1"]};
  border: 1px dashed ${color["primary"]};
  border-radius: 12px;
`;

const CollapseContainer = styled.div`
  .ant-collapse-content-box {
    background-color: ${color.background4} !important;
  }
`;

export const CreateProductQuota: React.FC = () => {
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const { company, firstname, lastname } = userProfile;
  const roleData = useRecoilValue(roleAtom);

  const navigate = useNavigate();

  const { pathname } = window.location;
  const pathSplit = pathname.split("/") as Array<string>;
  const id = pathSplit[3];
  const isEditing = !!id;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [quotaList, setQuotaList] = useState<any[]>([]);
  const [modalStep, setModalStep] = useState<0 | 1 | 2>(0);
  const [productList, setProductList] = useState<any[]>();
  const [storeList, setStoreList] = useState<StoreEntity[]>();
  const [deleteProductList, setDeleteProductList] = useState<number[]>([]);
  const [deleteStoreList, setDeleteStoreList] = useState<number[]>([]);
  const [avilability, setAvilability] = useState<any[]>([]);

  const [oldData, setOldaData] = useState<any>();
  const [isLaunched, setLaunched] = useState(false);
  const [zoneData, setZoneData] = useState<{ label: string; value: string; key: string }[]>([]);
  const [addStoreForProduct, setAddStoreFP] = useState();
  const [showError, setShowError] = useState(false);

  const [showDeleteError, setShowDeleteError] = useState(false);
  const [showAvilableError, setShowAvilableError] = useState(false);

  const [search, setSearch] = useState<string>("");

  useEffectOnce(() => {
    if (isEditing) {
      fetchData();
    }
    if (zoneData.length <= 0) {
      getZoneByCompany();
    }
  });

  useEffect(() => {
    if (productList?.length) checkProductAvailability();
  }, [productList]);

  const fetchData = async () => {
    setLoading(true);
    const quotaData = await getProductQuotaById(id);
    form.setFieldsValue({
      ...quotaData,
      startDate: dayjs(quotaData.startDate),
      endDate: dayjs(quotaData.endDate),
      startTime: dayjs(quotaData.startDate),
      endTime: dayjs(quotaData.endDate),
    });
    setLaunched(isEditing && dayjs(quotaData.startDate).isBefore(dayjs()));

    const productQuota = await getProductQuotaProduct({
      quotaId: id,
      page: 1,
      take: 1000,
    });
    const newProductList = productQuota?.data?.map((p) => ({ ...p, stores: p.shopQuota }));
    setProductList(newProductList);
    setOldaData({
      ...quotaData,
      productQuota,
    });
    setLoading(false);
  };

  const checkProductAvailability = async () => {
    const productQuota = productList
      ?.filter((p) => p.stores.length)
      .map((p) => ({
        productId: p.productId,
        customerCompanyId: p?.stores?.map((s) => s.customerCompanyId),
      }));

    await checkProductShop({
      productQuota,
    }).then((data) => {
      if (Array.isArray(data)) {
        setAvilability(data);
      } else {
        console.log("checkProductAvailability", data);
      }
    });
  };

  const getZoneByCompany = async () => {
    const res = await zoneDatasource.getAllZoneByCompany(company);
    const data = res.map((item: any) => {
      return {
        label: item.zoneName,
        value: item.zoneName,
        key: item.zoneId,
      };
    });
    setZoneData(data);
  };

  const PageTitle = () => {
    return (
      <PageTitleNested
        title={(isEditing ? "แก้ไข" : "สร้าง") + "โควตา"}
        showBack
        customBreadCrumb={
          <BreadCrumb
            data={[
              { text: "รายการกำหนดโควตาสินค้า", path: "/productQuota" },
              {
                text: "สร้างโควตา",
                path: window.location.pathname,
              },
            ]}
          />
        }
        onBack={() => {
          Modal.confirm({
            title: (
              <Text level={3} fontWeight={700}>
                ยืนยันการกดย้อนกลับ
              </Text>
            ),
            content: (
              <>
                <Text color='error'>
                  หากกดย้อนกลับข้อมูลการกำหนดโควตาสินค้าและร้านค้าจะไม่ถูกบันทึกโปรดตรวจสอบให้แน่ใจก่อนยืนยัน
                </Text>
                <Divider style={{ margin: "8px 0px 0px" }} />
              </>
            ),
            icon: null,
            okButtonProps: { type: "primary" },
            cancelButtonProps: {},
            onOk: () => {
              navigate(-1);
            },
          });
        }}
      />
    );
  };

  const onQuotaChange = (key: string, value: any) => {
    const newList = {
      ...quotaList,
    };
    newList[key] = value;
    setQuotaList(newList);
  };

  const onSubmit = async () => {
    form
      .validateFields()
      .then(async (f) => {
        if (!productList || !productList.length) {
          setShowError(true);
          return;
        }
        setSubmitting(true);

        // clear deleted
        let pass = true;
        if (deleteProductList.length || deleteStoreList.length) {
          const deletedList = {
            productQuotaId: deleteProductList,
            shopQuotaId: deleteStoreList,
          };
          // TODO : call api

          await deleteQuotaProductQuota(deletedList).then((res) => {
            //console.log("delete completed", res);
            if (!res?.success) {
              pass = false;
            }
          });
        }
        if (!pass) {
          //console.log("delete error");
          setShowDeleteError(true);
          setSubmitting(false);
          return;
        }

        const productQuota = productList?.map((p: any) => {
          const thisAvilability = avilability?.find((p2) => `${p2.productId}` === p?.productId)
            ?.shopQuota;
          const shopQuota = p.stores
            ?.filter((s) => {
              if (
                !thisAvilability?.find(
                  (c) => `${c?.customerCompanyId}` === `${s?.customerCompanyId}`,
                )?.productShopId
              )
                return false;
              return quotaList[`quota-${p.productId}-${s.customerCompanyId}`] || s.shopQuotaId;
            })
            .map((s) => ({
              ...s,
              quotaReceive:
                quotaList[`quota-${p.productId}-${s.customerCompanyId}`] || s.quotaReceive,
              quotaUsed: undefined,
              productQuotaId: p.productQuotaId,
              quotaId: id,
            }));

          if (!shopQuota.length) {
            pass = false;
          }
          return {
            ...p,
            quotaId: id,
            shopQuota,
          };
        });
        if (!pass) {
          //console.log("shop list error");
          setShowAvilableError(true);
          setSubmitting(false);
          return;
        }

        const submitData = {
          ...f,
          quotaId: isEditing ? id : undefined,
          company,
          updatedBy: `${firstname} ${lastname}`,
          productQuota,
          startDate:
            f.startDate && f.startTime
              ? dayjs(
                  `${f.startDate.format("YYYY-MM-DD")} ${f.startTime.format("HH:mm")}:00.000`,
                ).toISOString()
              : undefined,
          endDate:
            f.endDate && f.endTime
              ? dayjs(
                  `${f.endDate.format("YYYY-MM-DD")} ${f.endTime.format("HH:mm")}:00.000`,
                ).toISOString()
              : undefined,
        };
        const callback = (d) => {
          navigate("/productQuota");
          setSubmitting(false);
        };
        const callbackErr = (e) => {
          setSubmitting(false);
        };
        if (isEditing) {
          await updateProductQuota(submitData).then(callback).catch(callbackErr);
        } else {
          await createProductQuota(submitData).then(callback).catch(callbackErr);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const ErrorMessage = () => {
    return (
      <>
        {showError && (
          <>
            <br />
            <Text level={5} color='error'>
              โปรดกำหนดโควตาสินค้ารายร้าน
            </Text>
          </>
        )}
        {!showError && showAvilableError && (
          <>
            <br />
            <Text level={5} color='error'>
              โปรดกำหนดร้านในแต่ละโควตาสินค้า
            </Text>
          </>
        )}
      </>
    );
  };

  return (
    <OuterCardContainer>
      <CardContainer cutBottom>
        <PageTitle />
      </CardContainer>
      <Divider />
      <CardContainer cutTop>
        {loading ? (
          <PageSpin />
        ) : (
          <Form form={form} layout='vertical' requiredMark={false}>
            <>
              <Text level={5} fontWeight={700}>
                รายละเอียดโควตา
              </Text>
              <br />
              <br />
              <Row>
                <Col span={12}>
                  <Form.Item
                    label={requiredLabel("ชื่อโควตา")}
                    name='quotaName'
                    rules={[{ required: true, message: "โปรดระบุชื่อโควตา" }]}
                    required
                  >
                    <Input placeholder='ระบุชื่อโควตา' />
                  </Form.Item>
                </Col>
              </Row>
              <Row align='middle' gutter={16}>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='startDate'
                        label='วันที่เริ่มโควตา'
                        rules={[
                          {
                            required: true,
                            message: "*โปรดเลือกวันที่เริ่มต้น",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} enablePast disabled={isLaunched} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='startTime'
                        label='เวลาเริ่มต้น'
                        initialValue={dayjs("00:00", "HH:mm")}
                      >
                        <TimePicker allowClear={false} disabled={isLaunched} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='endDate'
                        label='วันที่สิ้นสุดโควตา'
                        rules={[
                          {
                            required: true,
                            message: "*โปรดเลือกวันที่สิ้นสุด",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={(current: Dayjs) => {
                            const startDate = form.getFieldValue("startDate");
                            return current && current.isBefore(dayjs(startDate));
                          }}
                          enablePast
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='endTime'
                        label='เวลาสิ้นสุดโควตา'
                        initialValue={dayjs("23:59", "HH:mm")}
                      >
                        <TimePicker
                          allowClear={false}
                          disabledTime={(now) => {
                            const startDate = form.getFieldValue("startDate") as Dayjs;
                            const endDate = form.getFieldValue("endDate") as Dayjs;
                            const startTime = form.getFieldValue("startTime") as Dayjs;
                            const isSameDay =
                              startDate && endDate && startDate?.isSame(endDate, "year");
                            if (!isSameDay) return {};

                            const hour = startTime.hour();
                            const minute = startTime.minute();
                            const hours: number[] = [];
                            const minutes: number[] = [];
                            for (let i = 0; i < hour; i++) {
                              hours.push(i);
                            }
                            for (let i = 0; i <= minute; i++) {
                              minutes.push(i);
                            }
                            return {
                              disabledHours: () => hours,
                              disabledMinutes: (selectedHour: number) =>
                                selectedHour === hour ? minutes : [],
                            };
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
            <Divider />
            <Row align='top'>
              <Col span={18}>
                <Text level={5} fontWeight={700}>
                  กำหนดโควตาสินค้ารายร้าน&nbsp;<span style={{ color: color.error }}>*</span>
                </Text>
                <br />
                <Text color='Text3' level={6}>
                  <ExclamationCircleOutlined style={{ fontSize: 16, color: color.Text3 }} />
                  &nbsp;ร้านค้าที่ไม่ได้ถูกเลือกในโควตาสินค้า สินค้าจะถูกแสดงเป็น “หมดชั่วคราว”
                  ในหน้าแอปพลิเชัน Shop App
                </Text>
                <ErrorMessage />
              </Col>
              <Col span={6}>
                <Row justify='end'>
                  <Button
                    title='เพิ่มร้านค้าและสินค้า'
                    icon={<PlusOutlined />}
                    style={{ width: 200 }}
                    onClick={() => setModalStep(1)}
                  />
                </Row>
              </Col>
            </Row>
            <Row align='bottom' justify='space-between' style={{ margin: "12px 0px" }}>
              <Col span={4}>
                <Input
                  placeholder='ค้นหารหัส/ชื่อร้าน'
                  suffix={<SearchOutlined />}
                  onChange={(e) => {
                    const value = (e.target as HTMLTextAreaElement).value;
                    setSearch(value);
                  }}
                  value={search}
                />
              </Col>
              <Col span={4}>
                <FlexRow justify='end'>
                  <Text>สินค้าทั้งหมด&nbsp;{productList?.length || 0}&nbsp;รายการ&nbsp;</Text>
                </FlexRow>
              </Col>
            </Row>
            <CollapseContainer>
              {productList?.length ? (
                productList
                  ?.filter((item) => {
                    if (search && !item?.productName?.includes(search)) return false;
                    return true;
                  })
                  .map((item) => {
                    return (
                      <Collapse
                        key={item.productId}
                        defaultActiveKey={productList?.map((item) => item.productId)}
                        collapsible='icon'
                        expandIconPosition='end'
                        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
                      >
                        <Collapse.Panel
                          header={
                            <CollapsePanelHeader
                              item={item}
                              totalStore={item?.stores?.length || 0}
                              totalQuota={
                                item?.stores?.reduce((acc, s) => acc + (s?.quotaReceive || 0), 0) +
                                Object.entries(quotaList).reduce(
                                  (acc, [key, val]) =>
                                    key.split("-")[1] === item?.productId
                                      ? acc + parseFloat(val)
                                      : acc,
                                  0,
                                )
                              }
                              editMode={isEditing}
                              onDeleteProd={() => {
                                Modal.confirm({
                                  title: (
                                    <Text level={3} fontWeight={700}>
                                      ยืนยันการลบสินค้า
                                    </Text>
                                  ),
                                  content: (
                                    <>
                                      <Text>
                                        โปรดตรวจสอบรายละเอียดอีกครั้ง ก่อนการกดยืนยันการลบ
                                      </Text>
                                      <Divider style={{ margin: "8px 0px 0px" }} />
                                    </>
                                  ),
                                  icon: null,
                                  okButtonProps: { style: { backgroundColor: color.error } },
                                  cancelButtonProps: {
                                    style: { color: color.error, borderColor: color.error },
                                  },
                                  onOk: () => {
                                    if (item.productQuotaId)
                                      setDeleteProductList([
                                        ...deleteProductList,
                                        item.productQuotaId,
                                      ]);
                                    setProductList(
                                      productList.filter(
                                        (p) => `${p.productId}` !== `${item.productId}`,
                                      ),
                                    );
                                  },
                                });
                              }}
                            />
                          }
                          key={item.productId}
                        >
                          <CollapsePanelItem
                            form={form}
                            productQuotaId={item?.productQuotaId}
                            storeList={item?.stores || []}
                            zoneData={zoneData}
                            productId={item.productId}
                            onQuotaChange={onQuotaChange}
                            editMode={isEditing}
                            avilability={
                              (avilability || [])?.find((p) => `${p.productId}` === item?.productId)
                                ?.shopQuota || []
                            }
                            onAddStore={() => setAddStoreFP(item.productId)}
                            onChangeQuota={({
                              productId,
                              customerCompanyId,
                              quotaReceive,
                              quotaChange,
                            }) => {
                              setShowError(false);
                              setShowAvilableError(false);
                              setProductList(
                                productList?.map((p) => {
                                  if (`${p.productId}` !== `${productId}`) return p;
                                  const stores = p?.stores?.map((s) => {
                                    if (`${s.customerCompanyId}` !== `${customerCompanyId}`)
                                      return s;
                                    return { ...s, quotaReceive, quotaChange };
                                  });
                                  return {
                                    ...p,
                                    stores,
                                  };
                                }),
                              );
                            }}
                            onDeleteStore={(l: any[]) => {
                              setDeleteStoreList([
                                ...deleteStoreList,
                                ...l.map((s) => s.shopQuotaId).filter((r) => !!r),
                              ]);
                              setProductList(
                                productList?.map((p) => {
                                  if (`${p.productId}` !== `${item.productId}`) return p;
                                  const stores = p?.stores?.filter(
                                    (s) =>
                                      !l.find(
                                        (ds) =>
                                          `${ds.customerCompanyId}` === `${s.customerCompanyId}`,
                                      ),
                                  );
                                  return {
                                    ...p,
                                    stores,
                                  };
                                }),
                              );
                            }}
                          />
                        </Collapse.Panel>
                      </Collapse>
                    );
                  })
              ) : (
                <AddProductContainer onClick={() => setModalStep(1)}>
                  <FlexCol style={{ width: "100%" }} align='center'>
                    <img
                      src={image.addProduct}
                      style={{ width: 70, height: 80, marginBottom: 12 }}
                    />
                    <Text level={3} color='primary'>
                      +&nbsp;เพิ่มร้านค้าและสินค้า
                    </Text>
                    <Text level={5}>โปรดเลือกร้านค้าและสินค้าที่ต้องการ</Text>
                    <Text level={5} style={{ marginTop: -6 }}>
                      จากนั้นระบุจำนวนโควตาที่กำหนดแต่ละร้าน
                    </Text>
                  </FlexCol>
                </AddProductContainer>
              )}
            </CollapseContainer>
          </Form>
        )}
        <Divider />
        <Row justify='end'>
          <Col span={3}>
            <Button title='บันทึก' onClick={onSubmit} loading={loading} />
          </Col>
        </Row>
        <Row justify='end'>
          <ErrorMessage />
        </Row>
      </CardContainer>
      <Modal open={modalStep === 2} width={1350} closable={false} footer={null}>
        <AddProduct
          list={(productList as ProductEntity[]) || []}
          setList={(l) => {
            setShowError(false);
            setShowAvilableError(false);
            setProductList([
              ...(productList || []),
              ...(l?.map((p) => ({
                ...p,
                stores: storeList,
              })) || []),
            ]);
          }}
          onClose={() => {
            setModalStep(0);
            setStoreList([]);
          }}
          withBackButton={{ title: "ย้อนกลับ", onBack: () => setModalStep(1) }}
          customTitle={
            <FlexCol>
              <Text level={4} fontWeight={700}>
                เพิ่มร้านค้าและสินค้า
              </Text>
              <br />
              <FlexRow align='end'>
                <Text fontWeight={700}>ขั้นตอน 2/2 : เลือกสินค้า</Text>&nbsp;
                <Text color='Text3' level={6}>
                  เลือกได้มากกว่า 1 สินค้า
                </Text>
              </FlexRow>
            </FlexCol>
          }
        />
      </Modal>
      {modalStep === 1 && (
        <ModalSelectStore
          showModalShop={modalStep === 1}
          callBackShop={(stores) => {
            setStoreList(stores);
            setModalStep(2);
          }}
          currentSelectShop={[]}
          company={company}
          onClose={() => setModalStep(0)}
          customerType={"DL"}
          replaceSaveButtonTitle={"ถัดไป"}
          replaceTitle={
            <FlexCol>
              <Text level={4} fontWeight={700}>
                เพิ่มร้านค้าและสินค้า
              </Text>
              <br />
              <FlexRow align='end'>
                <Text fontWeight={700}>ขั้นตอน 1/2 : เลือกร้านค้า</Text>&nbsp;
                <Text color='Text3' level={6}>
                  เลือกได้มากกว่า 1 ร้านค้า
                </Text>
              </FlexRow>
            </FlexCol>
          }
          hideCurrect
        />
      )}
      {!!addStoreForProduct && (
        <>
          <ModalSelectStore
            showModalShop={!!addStoreForProduct}
            callBackShop={(stores) => {
              setProductList(
                productList?.map((p: any) => {
                  if (`${p.productId}` === `${addStoreForProduct}`)
                    return { ...p, stores: [...(p.stores || []), ...(stores || [])] };
                  return p;
                }),
              );
              setShowError(false);
              setShowAvilableError(false);
              setAddStoreFP(undefined);
            }}
            currentSelectShop={
              productList?.find((p) => `${p.productId}` === `${addStoreForProduct}`)?.stores || []
            }
            company={company}
            onClose={() => setAddStoreFP(undefined)}
            customerType={"DL"}
            hideCurrect
          />
        </>
      )}

      <Modal open={submitting} footer={null} width={220} closable={false}>
        <FlexCol align='center' justify='center' style={{ width: 172, height: 172 }}>
          <Spin size='large' />
          <br />
          <Text level={4} align='center'>
            กำลัง{isEditing ? "แก้ไข" : "สร้าง"}
            <br />
            โควตา
          </Text>
        </FlexCol>
      </Modal>
      <Modal open={showDeleteError} footer={null} width={360} closable={false}>
        <FlexCol align='center'>
          <img src={image.errorState} style={{ width: 200 }} />
          <br />
          <Text level={3} fontWeight={700}>
            ไม่สามารถลบร้านค้าได้
          </Text>
          <Text color='Text3'>เนื่องจากร้านค้านี้มีการใช้โควตาไปแล้วในระบบ</Text>
          <Text color='Text3'>กรุณาตรวจสอบข้อมูลโควตาก่อนดำเนินการ</Text>
          <Button
            title='เข้าใจแล้ว'
            onClick={() => {
              setShowDeleteError(false);
            }}
          />
        </FlexCol>
      </Modal>
    </OuterCardContainer>
  );
};
