import React, { useEffect, useState } from "react";
import { ProductEntity } from "../../../entities/PoductEntity";
import { Col, Form, Modal, Progress, Row, Table } from "antd";
import { FlexCol, FlexRow } from "../../../components/Container/Container";
import { ProductName } from "../../Shared/AddProduct";
import Text from "../../../components/Text/Text";
import { numberFormatter, priceFormatter } from "../../../utility/Formatter";
import { FormInstance } from "antd/lib";
import { StoreEntity } from "../../../entities/StoreEntity";
import TableContainer from "../../../components/Table/TableContainer";
import Input from "../../../components/Input/Input";
import { CloseOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import Select from "../../../components/Select/Select";
import { dealerTypeName } from "../../../definitions/campaign";
import Button from "../../../components/Button/Button";
import icon from "../../../resource/icon";
import color from "../../../resource/color";
import { onInputNumberKeyPress } from "../../../utility/validator";
import styled from "styled-components";
import { useForm } from "antd/lib/form/Form";
import Radio from "../../../components/Radio/Radio";
import { requiredLabel } from "../../../components/Form/FormItemComponents";
import { ActionBtn, ActionBtnWhite } from "../../../components/Button/TableActionButton";

const EditQuotaModal = styled(Modal)`
  .ant-modal-content {
    padding: 0 !important;
  }
`;

export const CollapsePanelHeader = ({
  item,
  totalStore,
  totalQuota,
  totalUsedQuota,
  editMode,
  onDeleteProd,
  viewOnly,
}: {
  item: ProductEntity;
  totalStore: number;
  totalQuota: number;
  totalUsedQuota?: number;
  editMode?: boolean;
  onDeleteProd?: () => void;
  viewOnly?: boolean;
}) => {
  return (
    <Row style={{ padding: "24px 32px" }}>
      <Col span={viewOnly ? 11 : 10}>
        <FlexRow>
          <ProductName product={item} size={84} align='start' />
        </FlexRow>
      </Col>
      <Col span={4}>
        <Text>{item.productCodeNAV}</Text>
        <br />
        <Text color='Text3' level={6}>
          Pack Size:&nbsp;{item.packSize}
        </Text>
      </Col>
      <Col span={4}>
        <Text>จำนวนร้านค้า</Text>
        <br />
        <Text>{totalStore}&nbsp;ร้าน</Text>
      </Col>
      <Col span={viewOnly ? 5 : 4}>
        <Text>{viewOnly ? "โควตาถูกใช้ / ทั้งหมด " : "โควตาที่ให้ทั้งหมด"}</Text>
        <br />
        <Text>
          {viewOnly && (
            <>{priceFormatter(totalUsedQuota || "", 2, false, true)}&nbsp;ตัน&nbsp;/&nbsp;</>
          )}
          {priceFormatter(totalQuota || "", 2, false, true)}&nbsp;ตัน
        </Text>
      </Col>
      <Col span={viewOnly ? 0 : 2}>
        {totalUsedQuota ? (
          <ActionBtn
            icon={<img src={icon.deleteText4} style={{ width: 16, height: 16 }} />}
            disabled
          />
        ) : (
          <ActionBtnWhite
            onClick={onDeleteProd}
            icon={<img src={icon.delete} style={{ width: 14, height: 16 }} />}
          />
        )}
      </Col>
    </Row>
  );
};

export const CollapsePanelItem = ({
  form,
  storeList,
  zoneData,
  productId,
  onQuotaChange,
  editMode,
  onChangeQuota,
  onAddStore,
  productQuotaId,
  onDeleteStore,
  avilability,
  viewOnly,
}: {
  form?: FormInstance;
  storeList: StoreEntity[];
  zoneData: any[];
  productId: number | string;
  onQuotaChange: any;
  editMode?: boolean;
  onChangeQuota?: (a: any) => void;
  onAddStore?: () => void;
  productQuotaId?: string;
  onDeleteStore?: (a: any) => void;
  avilability: any[];
  viewOnly?: boolean;
}) => {
  const [editForm] = useForm();
  const [editModal, setEditModal] = useState<any>({
    open: false,
    data: null,
  });
  const closeModal = () => {
    setEditModal({ open: false, data: null });
    editForm.resetFields();
  };

  const [zone, setZone] = useState<string>();
  const [search, setSearch] = useState<string>("");
  const [filteredData, setFilteredData] = useState(
    storeList?.map((s) => ({ ...s, key: s.customerCompanyId })),
  );
  useEffect(() => {
    const newList = storeList
      ?.filter((s) => {
        if (search && !s.customerName?.includes(search) && !s.customerNo?.includes(search))
          return false;

        if (zone && s.zone !== zone) return false;

        return true;
      })
      .map((s) => ({ ...s, key: s.customerCompanyId }));
    setFilteredData(newList);
    setSelectedRowKeys([]);
  }, [zone, search, storeList]);

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();

  const rowSelection = viewOnly
    ? undefined
    : {
        selectedRowKeys: selectedRowKeys?.map((r) => r.customerCompanyId),
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows);
          setSelectedRowKeys(selectedRows);
        },
        getCheckboxProps: (record: any) => ({
          name: record.customerCompanyId,
          disabled: record.quotaUsed,
        }),
      };

  const columns = [
    {
      title: "รหัสร้านค้า",
      dataIndex: "customerNo",
      key: "customerNo",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
    {
      title: "ชื่อร้านค้า",
      dataIndex: "customerName",
      key: "customerName",
      render: (value: any, row: any, index: number) => {
        const isAvilable = avilability?.find(
          (c) => `${c?.customerCompanyId}` === `${row?.customerCompanyId}`,
        )?.productShopId;
        return {
          children: (
            <>
              <Text>{value}</Text>
              <br />
              {!isAvilable && !viewOnly && (
                <Text level={6} color='error'>
                  ร้านนี้ไม่มีสินค้าดังกล่าวจำหน่ายในร้าน
                </Text>
              )}
            </>
          ),
        };
      },
    },
    {
      title: "ประเภทคู่ค้า",
      dataIndex: "dealerType",
      key: "dealerType",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text level={6}>{dealerTypeName["DEALER"]}</Text>,
        };
      },
    },
    {
      title: "เขตการขาย",
      dataIndex: "zone",
      key: "zone",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Text>{value}</Text>,
        };
      },
    },
  ];

  const columnCreate = [
    ...columns,
    {
      title: "กำหนดโควตา",
      dataIndex: "customerCompanyId",
      key: "quota",
      render: (customerCompanyId: any, row: any, index: number) => {
        const keyName = `quota-${productId}-${customerCompanyId}`;
        const isAvilable = avilability?.find(
          (c) => `${c?.customerCompanyId}` === `${row?.customerCompanyId}`,
        )?.productShopId;
        return isAvilable ? (
          <Form.Item name={keyName} rules={[{ required: true, message: "โปรดระบุโควตา" }]}>
            <Input
              placeholder='ระบุจำนวนโควตา'
              suffix='ตัน'
              onKeyDown={onInputNumberKeyPress}
              onChange={(e) => {
                onQuotaChange(keyName, e.target.value);
              }}
            />
          </Form.Item>
        ) : (
          <></>
        );
      },
    },
  ];

  const columnView = [
    ...columns,
    {
      title: "โควตาถูกใช้ / ทั้งหมด",
      dataIndex: "customerCompanyId",
      key: "quota",
      render: (customerCompanyId: any, row: any, index: number) => {
        const { quotaReceive, quotaUsed } = row;
        const percent = (quotaUsed * 100) / quotaReceive;
        const keyName = `quota-${productId}-${customerCompanyId}`;
        const isAvilable = avilability?.find(
          (c) => `${c?.customerCompanyId}` === `${row?.customerCompanyId}`,
        )?.productShopId;
        return !row?.shopQuotaId ? (
          isAvilable ? (
            <Form.Item name={keyName} rules={[{ required: true, message: "โปรดระบุโควตา" }]}>
              <Input
                placeholder='ระบุจำนวนโควตา'
                suffix='ตัน'
                onKeyDown={onInputNumberKeyPress}
                onChange={(e) => {
                  onQuotaChange(keyName, e.target.value);
                }}
              />
            </Form.Item>
          ) : (
            <></>
          )
        ) : (
          <FlexCol>
            <Text>
              {numberFormatter(quotaUsed, 2)}&nbsp;/&nbsp;{numberFormatter(quotaReceive, 2)}
              &nbsp;ตัน
            </Text>
            <Progress percent={percent} showInfo={false} />
          </FlexCol>
        );
      },
    },
  ];

  const columnEdit = [
    ...columnView,
    {
      title: "โควตาแก้ไข *",
      dataIndex: "customerCompanyId",
      key: "edit",
      render: (customerCompanyId: any, data: any, index: number) => {
        const { quotaChange, quotaReceive } = data;
        const wasChanged = quotaChange > 0 || quotaChange < 0;
        return !data?.shopQuotaId ? (
          <></>
        ) : wasChanged ? (
          <FlexRow align='center'>
            <Text color={quotaChange > 0 ? "success" : "error"}>
              {quotaChange > 0 ? "+" : "-"}&nbsp;{Math.abs(quotaChange)}&nbsp;&nbsp;ตัน
            </Text>
            &nbsp;
            <CloseOutlined
              style={{ fontSize: 10, fontWeight: 700 }}
              onClick={() => {
                if (onChangeQuota)
                  onChangeQuota({
                    productId,
                    customerCompanyId,
                    quotaReceive: quotaReceive - quotaChange,
                    quotaChange: 0,
                  });
              }}
            />
          </FlexRow>
        ) : (
          <Button
            title='เพิ่ม/ลดโควตา'
            typeButton='primary-light'
            onClick={() =>
              setEditModal({
                open: true,
                data,
              })
            }
          />
        );
      },
    },
  ];

  return (
    <>
      <Row gutter={12} style={{ marginBottom: 12 }}>
        <Col span={4}>
          <Input
            placeholder='ค้นหารหัส/ชื่อร้าน'
            suffix={<SearchOutlined />}
            onChange={(e) => {
              const value = (e.target as HTMLTextAreaElement).value;
              setSearch(value);
            }}
            value={search}
          />
        </Col>
        <Col span={3}>
          <Select
            placeholder='เขตทั้งหมด'
            data={zoneData}
            value={zone}
            style={{ width: 150 }}
            onChange={(z) => {
              setZone(z);
            }}
          />
        </Col>
        <Col span={viewOnly ? 17 : 11} />
        <Col span={viewOnly ? 0 : 3}>
          <Button
            title='ลบร้านค้า'
            icon={
              <img
                src={!selectedRowKeys?.length ? icon.deleteText4 : icon.deleteWhite}
                style={{ width: 16, height: 16 }}
              />
            }
            typeButton={!selectedRowKeys?.length ? "light-disabled" : "danger"}
            disabled={!selectedRowKeys?.length}
            onKeyDown={onInputNumberKeyPress}
            onClick={() => {
              const deletedList = [...(selectedRowKeys || [])];
              if (onDeleteStore) onDeleteStore(deletedList);
              setSelectedRowKeys([]);
            }}
          />
        </Col>
        <Col span={viewOnly ? 0 : 3}>
          <Button title='เพิ่มร้านค้า' icon={<PlusOutlined />} onClick={onAddStore} />
        </Col>
      </Row>
      <TableContainer>
        <Table
          columns={editMode && productQuotaId ? columnEdit : viewOnly ? columnView : columnCreate}
          dataSource={filteredData}
          pagination={false}
          rowSelection={rowSelection}
        />
      </TableContainer>
      <EditQuotaModal
        open={editModal.open}
        width={440}
        closable={false}
        onCancel={closeModal}
        footer={<Row></Row>}
      >
        <Row align='top' justify='space-between' style={{ padding: "20px 24px 12px" }}>
          <Text level={3} fontWeight={700}>
            เพิ่ม/ลดโควตารายร้าน
          </Text>
          <CloseOutlined onClick={closeModal} />
        </Row>
        <FlexCol style={{ padding: "16px 24px", backgroundColor: "#F3F6F9" }}>
          <Text>
            <span style={{ fontWeight: 700 }}>ชื่อร้านค้า:&nbsp;</span>
            {editModal?.data?.customerName}
          </Text>
          <Text>
            <span style={{ fontWeight: 700 }}>โควตาถูกใช้ / ทั้งหมด:&nbsp;</span>
            {numberFormatter(editModal?.data?.quotaUsed, 2)}&nbsp;/&nbsp;
            {numberFormatter(editModal?.data?.quotaReceive, 2)}&nbsp;ตัน
          </Text>
        </FlexCol>
        <div style={{ padding: "16px 24px", borderBottom: "1px solid #E5EAEE" }}>
          <Form form={editForm} layout='vertical' requiredMark={false}>
            <Form.Item
              name='factor'
              label={requiredLabel("เลือกรูปแบบโควตา")}
              rules={[{ required: true, message: "โปรดเลือกรูปแบบโควตา" }]}
            >
              <Radio
                items={[
                  {
                    key: "1",
                    value: 1,
                    label: "เพิ่มโควตา",
                  },
                  {
                    key: "-1",
                    value: -1,
                    label: "ลดโควตา",
                  },
                ]}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                }}
              />
            </Form.Item>
            <Form.Item
              name='quota'
              label={requiredLabel("จำนวนโควตา")}
              rules={[
                {
                  required: true,
                  message: "โปรดระบุจำนวนโควตา",
                },
                {
                  message: "กรุณาลดโควตาให้ไม่เกินจำนวนที่เหลืออยู่",
                  validator(rule, value, callback) {
                    const { factor, quota } = editForm.getFieldsValue();
                    const quotaChange = factor * parseFloat(quota);
                    const nextValue = editModal?.data?.quotaReceive + quotaChange;
                    if (nextValue < 0)
                      return Promise.reject(new Error("กรุณาลดโควตาให้ไม่เกินจำนวนที่เหลืออยู่"));
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input placeholder='ระบุจำนวนโควตา' suffix='ตัน' onKeyDown={onInputNumberKeyPress} />
            </Form.Item>
          </Form>
        </div>
        <Row justify='end' gutter={12} style={{ padding: "16px 24px 8px" }}>
          <Col span={6}>
            <Button title='ยกเลิก' typeButton='primary-light' onClick={closeModal} />
          </Col>
          <Col span={6}>
            <Button
              title='บันทึก'
              typeButton='primary'
              onClick={() => {
                editForm
                  .validateFields()
                  .then((values) => {
                    const { factor, quota } = values;
                    const quotaChange = factor * parseFloat(quota);
                    const nextValue = editModal?.data?.quotaReceive + quotaChange;
                    if (nextValue < 0) return;

                    if (onChangeQuota)
                      onChangeQuota({
                        productId,
                        customerCompanyId: editModal?.data?.customerCompanyId,
                        quotaReceive: nextValue,
                        quotaChange,
                      });
                    closeModal();
                  })
                  .catch((e) => {
                    console.log("edit error");
                  });
              }}
            />
          </Col>
        </Row>
      </EditQuotaModal>
    </>
  );
};
