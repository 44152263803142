
import dayjs from "dayjs";

export const checkTimeRangeOverlap = (
  startA: string,
  endA: string,
  startB: string,
  endB: string,
): boolean => {
  const aStart = dayjs(startA, "HH:mm");
  const aEnd = dayjs(endA, "HH:mm");
  const bStart = dayjs(startB, "HH:mm");
  const bEnd = dayjs(endB, "HH:mm");

  return (
    (aStart.isBefore(bEnd) && bStart.isBefore(aEnd)) || aStart.isSame(bEnd) || bStart.isSame(aEnd)
  );
};
