import React, { Fragment, useEffect, useState } from "react";
import { Col, Checkbox, Switch, message } from "antd";
import { RowWrapper, Texts, TimePickerWrapper, TimeWrapper } from "../OrderPeriod.styles";
import Button from "../../../../components/Button/Button";
import TimePickerSection from "./TimePickerSection";
import dayjs, { Dayjs } from "dayjs";
import { checkTimeRangeOverlap } from "../../../../definitions/checkTimeOverlap";

interface DayTimePeriodProps {
  day: string;
  times: { startTime: string; endTime: string }[];
  checkedDays: boolean;
  isFullDay: boolean;
  isPeriodAdded: boolean;
  handleCheckboxChange: (day: string, isChecked: boolean) => void;
  onChangeSwitch: (day: string, checked: boolean) => void;
  onChangeStartTime: (
    day: string,
    index: number,
    date: Dayjs,
    dateString: string | string[],
  ) => void;
  onChangeEndTime: (day: string, index: number, date: Dayjs, dateString: string | string[]) => void;
  handleAddTimePeriod: (day: string) => void;
  handleRemoveTimePeriod: (day: string) => void;
}

const DayTimePeriod: React.FC<DayTimePeriodProps> = ({
  day,
  times = [],
  isFullDay,
  checkedDays,
  isPeriodAdded,
  handleCheckboxChange,
  onChangeSwitch,
  onChangeStartTime,
  onChangeEndTime,
  handleAddTimePeriod,
  handleRemoveTimePeriod,
}) => {
  const [disabledData, setDisabledData] = useState(checkedDays);
  const [isSwitch, setIsSwitch] = useState(isFullDay);
  const [isOverLap, setIsOverLap] = useState(false);

  const isHandleRemoveTimePeriod = (day) => {
    handleRemoveTimePeriod(day);
    setIsOverLap(false);
  };

  return (
    <RowWrapper key={day} gutter={times.length > 1 ? { xs: 8, sm: 8 } : { xs: 8, sm: 20 }}>
      <Col span={3}>
        <Checkbox
          checked={!checkedDays}
          onChange={(e) => {
            if (e.target.checked) {
              setDisabledData(false);
            } else {
              setDisabledData(true);
              setIsSwitch(false);
            }
            handleCheckboxChange(day, e.target.checked);
          }}
        >
          <Texts>{day}</Texts>
        </Checkbox>
      </Col>
      <Col span={2}>
        <Texts>เต็มวัน</Texts>
        <Switch
          disabled={disabledData}
          value={isSwitch}
          onChange={(checked) => {
            setIsSwitch(checked);
            onChangeSwitch(day, checked);
          }}
        />
      </Col>
      {!checkedDays && (
        <TimeWrapper isVisible={!isFullDay}>
          {times.length > 0 &&
            (() => {
              const formattedTimes = times.map((t) => ({
                startTime: t.startTime ? dayjs(t.startTime).format("HH:mm") : null,
                endTime: t.endTime ? dayjs(t.endTime).format("HH:mm") : null,
              }));

              const checkForOverlap = (
                startTime: string,
                endTime: string,
                currentIndex: number,
              ): boolean => {
                return formattedTimes.some(
                  (time, idx) =>
                    idx !== currentIndex &&
                    time.startTime &&
                    time.endTime &&
                    checkTimeRangeOverlap(time.startTime, time.endTime, startTime, endTime),
                );
              };

              return times.map((val, index) => (
                <React.Fragment key={index}>
                  <TimePickerSection
                    value={val.startTime ? dayjs(val.startTime) : null}
                    name='startTime'
                    span={times.length > 1 ? 5 : 10}
                    label='เวลาเริ่ม'
                    onChange={(date: any, dateString: any) => {
                      onChangeStartTime(day, index, date, dateString);
                      if (times.length > 1) {
                        const newOverlap = checkForOverlap(dateString, val.endTime || "", index);
                        if (isOverLap !== newOverlap) {
                          setIsOverLap(newOverlap);
                        }
                      }
                    }}
                    overLap={isOverLap}
                    endTime={dayjs(val.endTime)}
                  />

                  <TimePickerSection
                    value={val.endTime ? dayjs(val.endTime) : null}
                    name='endTime'
                    span={times.length > 1 ? 5 : 10}
                    label='เวลาสิ้นสุด'
                    overLap={isOverLap}
                    startTime={dayjs(val.startTime)}
                    onChange={(date: any, dateString: any) => {
                      onChangeEndTime(day, index, date, dateString);
                      if (times.length > 1) {
                        const newOverlap = checkForOverlap(val.startTime || "", dateString, index);
                        if (isOverLap !== newOverlap) {
                          setIsOverLap(newOverlap);
                        }
                      }
                    }}
                  />

                  {index < times.length - 1 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "8px",
                      }}
                    >
                      <Texts style={{ fontSize: 14 }}>และ</Texts>
                    </div>
                  )}
                </React.Fragment>
              ));
            })()}

          <Col style={{ marginTop: 8 }}>
            <TimePickerWrapper>
              <Button
                typeButton={isPeriodAdded ? "border-danger" : "border-primary"}
                title={isPeriodAdded ? "ยกเลิกการแบ่ง" : "+ แบ่งช่วงเวลา"}
                height={40}
                onClick={() =>
                  isPeriodAdded ? isHandleRemoveTimePeriod(day) : handleAddTimePeriod(day)
                }
              />
            </TimePickerWrapper>
          </Col>
        </TimeWrapper>
      )}
    </RowWrapper>
  );
};

export default DayTimePeriod;
