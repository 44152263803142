import { Col, Row } from "antd";
import styled from "styled-components";

export const Texts = styled.div<{ size?: string; color?: string; fontWeight?: number }>`
  font-family: "Sarabun", sans-serif;
  font-size: ${({ size = "14px" }) => size};
  color: ${({ color = "#464E5F" }) => color};
  font-weight: ${({ fontWeight = 400 }) => fontWeight};
`;

export const TimeWrapper = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-grow: 1;
  transition: opacity 0.3s ease-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  pointer-events: ${({ isVisible }) => (isVisible ? "auto" : "none")};
`;

export const RowWrapper = styled(Row)`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const TimePickerWrapper = styled(Col)`
  margin-top: 22px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
