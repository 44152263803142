import { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const baseUrl = `${BASE_URL}/master`;

const getProductQuotaById = async (quotaId: string) => {
  return await httpClient
    .get(`${baseUrl}/product-quota/quota-detail/${quotaId}`, { params: { quotaId } })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductQuotaList = async (params: object) => {
  return await httpClient
    .get(`${baseUrl}/product-quota/quota-list`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const changeStatusProductQuota = async (data: object) => {
  return await httpClient
    .post(`${baseUrl}/product-quota/update-status-quota`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const deleteProductQuota = async (params: object) => {
  return await httpClient
    .delete(`${baseUrl}/product-quota/delete-quota`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const getProductQuotaProduct = async (params: {
  quotaId: string;
  searchProduct?: string;
  searchShop?: string;
  zone?: string;
  sortField?: string;
  sortDirection?: string;
  page?: number;
  take?: number;
}) => {
  return await httpClient
    .get(`${baseUrl}/product-quota/quota-product-shop`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const createProductQuota = async (data: any) => {
  return await httpClient
    .post(`${baseUrl}/product-quota/create-quota`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const updateProductQuota = async (data: any) => {
  return await httpClient
    .post(`${baseUrl}/product-quota/update-quota`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const deleteQuotaProductQuota = async (data: any) => {
  return await httpClient
    .patch(`${baseUrl}/product-quota/delete-quota-product-shop`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

const checkProductShop = async (data: any) => {
  return await httpClient
    .post(`${baseUrl}/product-quota/check-product-shop`, data)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};

export {
  getProductQuotaById,
  getProductQuotaProduct,
  createProductQuota,
  updateProductQuota,
  getProductQuotaList,
  changeStatusProductQuota,
  deleteProductQuota,
  deleteQuotaProductQuota,
  checkProductShop,
};
