import React, { useState, useEffect, useCallback } from "react";
import { Divider, Spin, message } from "antd";
import moment from "moment";
import Button from "../../../../components/Button/Button";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";
import DayTimePeriod from "../ShareComponent/DayTimePeriod";
import {
  getOrderTimeMaster,
  updateOrderTimeMaster,
} from "../../../../datasource/OrderTimeDatasource";
import { ButtonWrapper, Texts } from "../OrderPeriod.styles";
import {
  OrderTimeMasterEntity_INIT,
  type OrderTimeMasterEntity,
} from "../../../../entities/OrderTimeEntity";
import dayjs, { Dayjs } from "dayjs";
import Swal from "sweetalert2";
import { checkTimeRangeOverlap } from "../../../../definitions/checkTimeOverlap";
import color from "../../../../resource/color";
import { InfoCircleFilled } from "@ant-design/icons";
import HowtoSetting, { getDescription } from "../Modal/HowtoSetting";

function OrderPeriod({ isRefresh }: { isRefresh: number }) {
  const [loading, setLoading] = useState(false);
  const [orderTimeState, setOrderTimeState] = useState<OrderTimeMasterEntity>(
    OrderTimeMasterEntity_INIT,
  );
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState<OrderTimeMasterEntity | null>(null);
  const userProfile = JSON.parse(localStorage.getItem("profile") || "{}");
  const [modalHowto, setModalHowto] = useState(false);

  useEffect(() => {
    getDataOrderTimeMaster();
  }, [isRefresh]);

  const getDataOrderTimeMaster = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getOrderTimeMaster(userProfile?.company);
      const newOrderTimeState: OrderTimeMasterEntity = res.data.reduce((acc, item) => {
        acc[item.day] = {
          fullDay: item.fullDay,
          isClose: item.isClose,
          times: item.times.map((time) => ({
            startTime: dayjs(time.startTime),
            endTime: dayjs(time.endTime),
          })),
        };
        return acc;
      }, {} as OrderTimeMasterEntity);

      setOrderTimeState(newOrderTimeState);
      setData(res);
    } catch (error) {
      console.error("Error fetching order time data:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getDataOrderTimeMaster();
  }, [getDataOrderTimeMaster]);

  const updateTimePeriods = useCallback(
    (day: string, index: number, timeType: "startTime" | "endTime", value: string) => {
      setOrderTimeState((prev) => {
        const updatedTimePeriods = [...prev[day].times];
        updatedTimePeriods[index][timeType] = value;
        return {
          ...prev,
          [day]: { ...prev[day], times: updatedTimePeriods },
        };
      });
    },
    [],
  );

  const handleAddTimePeriod = useCallback((day: string) => {
    setOrderTimeState((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        times: [...prev[day].times, { startTime: "", endTime: "" }],
      },
    }));
  }, []);

  const handleRemoveTimePeriod = useCallback((day: string) => {
    setOrderTimeState((prev) => {
      const updatedTimePeriods = prev[day].times.slice(0, -1);
      return {
        ...prev,
        [day]: { ...prev[day], times: updatedTimePeriods },
      };
    });
  }, []);

  const handleSwitch = (day: string, checked: boolean) => {
    setOrderTimeState((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        fullDay: checked,
        times: checked
          ? [
              {
                startTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                endTime: new Date(new Date().setHours(23, 59, 59, 0)).toISOString(),
              },
            ]
          : [
              {
                startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
              },
            ],
      },
    }));
  };

  const handleCheckboxChange = useCallback((day: string, isChecked: boolean) => {
    setOrderTimeState((prev) => ({
      ...prev,
      [day]: {
        ...prev[day],
        isClose: !isChecked,
        times: !isChecked
          ? []
          : [
              {
                startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
              },
            ],
      },
    }));
  }, []);

  const validateOrderTimeData = () => {
    for (const day of Object.keys(orderTimeState)) {
      const { fullDay, times, isClose } = orderTimeState[day];
      if (!fullDay && !isClose) {
        if (times.length === 0) {
          message.error(`กรุณากำหนดช่วงเวลา`);
          return false;
        }
        for (const time of times) {
          if (!time.startTime || !time.endTime) {
            message.error(`กรุณากำหนดเวลาเริ่มต้นและเวลาสิ้นสุดให้ครบถ้วน`);
            return false;
          }
        }
      }
    }
    return true;
  };
  const validateNoOverlappingTimes = () => {
    for (const day of Object.keys(orderTimeState)) {
      const { times } = orderTimeState[day];

      for (let i = 0; i < times.length; i++) {
        for (let j = i + 1; j < times.length; j++) {
          const { startTime: startA, endTime: endA } = times[i];
          const { startTime: startB, endTime: endB } = times[j];
          const startTimeA = dayjs(startA).format("HH:mm");
          const endTimeA = dayjs(endA).format("HH:mm");
          const startTimeB = dayjs(startB).format("HH:mm");
          const endTimeB = dayjs(endB).format("HH:mm");
          if (checkTimeRangeOverlap(startTimeA, endTimeA, startTimeB, endTimeB)) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const handleSave = async () => {
    const saveData = {
      data: Object.keys(orderTimeState).map((day) => {
        const { fullDay, isClose, times } = orderTimeState[day];
        const updatedFullDay = isClose ? false : fullDay;
        return { day, fullDay: updatedFullDay, isClose, times };
      }),
      updatedBy: `${userProfile?.firstname} ${userProfile?.lastname}`,
      company: userProfile?.company,
    };

    setLoading(true);
    try {
      await updateOrderTimeMaster(saveData);
      Swal.fire({
        icon: "success",
        title: "บันทึกสำเร็จ",
        text: "ข้อมูลถูกบันทึกเรียบร้อยแล้ว",
        timer: 1000,
        showConfirmButton: false,
      });

      setTimeout(() => {
        setShowModal(false);
        getDataOrderTimeMaster();
      }, 100);
    } catch (error) {
      console.error("Error saving order time data:", error);
      message.error("ไม่สามารถบันทึกข้อมูลได้ กรุณาลองใหม่อีกครั้ง");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin size='large' spinning={loading}>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 22 }}>
        <Texts>
          อัปเดทล่าสุดเมื่อ {moment(data?.updatedAt).format("DD/MM/YYYY, HH:mm")} โดย&nbsp;
          {data?.updatedBy}
        </Texts>
        <u style={{ display: "flex", color: color.primary }} onClick={() => setModalHowto(true)}>
          <InfoCircleFilled style={{ color: color.primary, marginRight: 4 }} />
          <Texts style={{ color: color.primary, fontWeight: 700, cursor: "pointer" }}>
            อ่านวิธีการใช้งานเบื้องต้น
          </Texts>
        </u>
      </div>

      <div style={{ marginTop: 22 }}>
        {Object.keys(orderTimeState).map((day) => (
          <DayTimePeriod
            key={day}
            day={day}
            checkedDays={orderTimeState[day].isClose}
            times={orderTimeState[day].times}
            isFullDay={orderTimeState[day].fullDay}
            isPeriodAdded={orderTimeState[day].times?.length > 1}
            onChangeSwitch={handleSwitch}
            onChangeStartTime={(date, index, value: any) =>
              updateTimePeriods(day, index, "startTime", value)
            }
            onChangeEndTime={(date, index, value: any) =>
              updateTimePeriods(day, index, "endTime", value)
            }
            handleAddTimePeriod={() => handleAddTimePeriod(day)}
            handleRemoveTimePeriod={() => handleRemoveTimePeriod(day)}
            handleCheckboxChange={handleCheckboxChange}
          />
        ))}
      </div>

      <Divider />

      <ButtonWrapper>
        <Button
          typeButton='primary'
          title='บันทึก'
          style={{ width: 134 }}
          height={40}
          onClick={() => {
            if (validateOrderTimeData() && validateNoOverlappingTimes()) {
              setShowModal(true);
            }
          }}
        />
      </ButtonWrapper>

      <ConfirmModal
        visible={showModal}
        onConfirm={handleSave}
        onCancel={() => setShowModal(false)}
        title='ต้องการบันทึกเวลาเปิด/ปิดคำ สั่งซื้อ(ร้านทั้งหมด) หรือไม่?'
        desc='โปรดตรวจสอบรายละเอียดช่วงเวลาอีกครั้งก่อนกดยืนยันเพราะอาจส่งผลต่อการแสดงผลคำสั่งซื้อในระบบ'
      />
      <HowtoSetting
        visible={modalHowto}
        onCancel={() => setModalHowto(false)}
        title='เวลาเปิด/ปิดคำสั่งซื้อ (ร้านทั้งหมด)'
        desc={getDescription("เวลาเปิด/ปิดคำสั่งซื้อ (ร้านทั้งหมด)")} 
      />
    </Spin>
  );
}

export default OrderPeriod;
