import React from "react";
import { Col, TimePicker, Form } from "antd";
import { Texts } from "../OrderPeriod.styles";
import { ClockCircleOutlined } from "@ant-design/icons";
import { color } from "../../../../resource";
import dayjs, { Dayjs } from "dayjs";
import type { filter } from "lodash";

interface TimePickerSectionProps {
  name: string;
  label: string;
  onChange: (date: Dayjs | null, dateString: string | string[]) => void;
  span?: number;
  value?: any;
  startTime?: Dayjs;
  endTime?: Dayjs;
  overLap?: boolean;
}

const TimePickerSection: React.FC<TimePickerSectionProps> = ({
  name,
  label,
  onChange,
  span = 10,
  value,
  startTime,
  endTime,
  overLap = false,
}) => {
  return (
    <Col span={span}>
      <div style={{ marginBottom: 8 }}>
        <Texts>{label}</Texts>
      </div>
      <Form.Item name={name}>
        <TimePicker
          defaultValue={value ? dayjs(value) : null}
          value={value ? dayjs(value) : null}
          placeholder={`เลือก${label}`}
          style={{
            width: "100%",
            height: "40px",
            borderColor: overLap ? color.error : color.background4,
          }}
          format='HH:mm'
          onChange={onChange}
          suffixIcon={<ClockCircleOutlined style={{ color: color.Text1, fontSize: 22 }} />}
          disabledTime={() => {
            if (name === "endTime" && startTime) {
              const startHour = dayjs(startTime).hour();
              const startMinute = dayjs(startTime).minute();

              return {
                disabledHours: () => Array.from({ length: startHour }, (_, i) => i),
                disabledMinutes: (hour) =>
                  hour === startHour ? Array.from({ length: startMinute + 1 }, (_, i) => i) : [],
              };
            }
            if (name === "startTime" && endTime) {
              const endHour = dayjs(endTime).hour();
              const endMinute = dayjs(endTime).minute();

              return {
                disabledHours: () =>
                  Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour > endHour),

                disabledMinutes: (hour) =>
                  hour === endHour
                    ? Array.from({ length: 60 }, (_, i) => i).filter((min) => min >= endMinute) // Disable minutes >= end minute
                    : [],
              };
            }
            return {};
          }}
        />
        {overLap && <Texts style={{ color: color.error }}>ช่วงเวลาทับซ้อนกัน</Texts>}
      </Form.Item>
    </Col>
  );
};

export default TimePickerSection;
