import React from "react";
import { Col, DatePicker, Form } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Texts } from "../OrderPeriod.styles";
import { color } from "../../../../resource";
import type { Dayjs } from "dayjs";
import Text from "../../../../components/Text/Text";

interface DatePickerSectionProps {
  name: string;
  label: string;
  onChange: (date: Dayjs | null, dateString: string | string[]) => void;
  span: number;
  placeholder: string;
  borderColor: string;
  err: boolean;
  errText?: string;
  format?: string;
  required?: boolean;
  disabledDate?: (current: Dayjs) => boolean;
}

const DatePickerSection: React.FC<DatePickerSectionProps> = ({
  name,
  label,
  onChange,
  span,
  placeholder,
  format = "DD/MM/YYYY",
  required = true,
  disabledDate,
  borderColor,
  err,
  errText,
}) => {
  const validationRules = required ? [{ required: true, message: `กรุณาเลือก${label}` }] : [];

  return (
    <Col span={span}>
      <div style={{ marginBottom: 8 }}>
        <Texts>
          <span style={{ color: color.error, marginRight: 4 }}>*</span>
          {label}
        </Texts>
      </div>
      <Form.Item name={name} rules={validationRules}>
        <DatePicker
          placeholder={placeholder}
          format={format}
          onChange={onChange}
          style={{ width: "100%", height: "40px", borderColor: borderColor }}
          suffixIcon={<CalendarOutlined style={{ color: color.Text1, fontSize: 22 }} />}
          disabledDate={disabledDate}
        />
        {err && (
          <Text fontFamily='Sarabun' fontSize={14} fontWeight={400} color='error'>
            {errText}
          </Text>
        )}
      </Form.Item>
    </Col>
  );
};

export default DatePickerSection;
