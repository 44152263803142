import React, { useEffect, useState } from "react";
import { Texts } from "../OrderPeriod.styles";
import TablePagination from "../../../../components/Table/TablePagination";
import { Col, Row } from "antd";
import Select from "../../../../components/Select/Select";
import Input from "../../../../components/Input/Input";
import { InfoCircleFilled, SearchOutlined } from "@ant-design/icons";
import { RangePicker } from "../../../../components/DatePicker/DatePicker";
import Button from "../../../../components/Button/Button";
import SortFieldCustom from "../../../../components/SortFieldTable/SortFieldCustom";
import useSorting from "../../../../hook/useSorting";
import moment from "moment";
import icon from "../../../../resource/icon";
import Text from "../../../../components/Text/Text";
import image from "../../../../resource/image";
import color from "../../../../resource/color";
import StatusBadge from "../../../../components/Badge/StatusBadge";
import ConfirmModal from "../../../../components/Modal/ConfirmModal";
import ManageSpecialHoliday from "../Modal/ManageSpecialHoliday";
import { deleteHoliday, getHolidays } from "../../../../datasource/OrderTimeDatasource";
import {
  AllHolidaysEntity_INIT,
  type AllHolidaysEntity,
} from "../../../../entities/OrderTimeEntity";
import HowtoSetting, { getDescription } from "../Modal/HowtoSetting";

function SpecialHoliday({ isRefresh }: { isRefresh: number }) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(1);
  const [dateFilter, setDateFilter] = useState<any>();
  const [type, setType] = useState<string | undefined>(undefined);
  const [status, setStatus] = useState<string | undefined>(undefined);
  const { sortField, sortDirection, handleSortChange } = useSorting();
  const [modalDelete, setModalDelete] = useState(false);
  const [isDays, setIsDays] = useState<string>("");
  const [addSpacialHoliday, setAddSpacialHoliday] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AllHolidaysEntity>(AllHolidaysEntity_INIT);
  const userProfile = JSON.parse(localStorage.getItem("profile") || "{}");
  const [holidayId, setHolidayId] = useState<string>("");
  const [modalHowto, setModalHowto] = useState(false);

  useEffect(() => {
    fetchHolidayData();
  }, [isRefresh]);
  useEffect(() => {
    fetchHolidayData();
  }, [page, searchText, status, type, dateFilter, sortDirection]);

  const fetchHolidayData = async () => {
    setLoading(true);
    try {
      await getHolidays(
        page,
        10,
        userProfile?.company,
        searchText,
        status,
        dateFilter && dateFilter[0] ? dateFilter[0].format("YYYY-MM-DD 00:00:00") : undefined,
        dateFilter && dateFilter[1] ? dateFilter[1].format("YYYY-MM-DD 23:59:59") : undefined,
        type,
        sortField,
        sortDirection,
      ).then((res) => {
        setData(res);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = (id: string, day: string) => {
    setModalDelete(true);
    setIsDays(day);
    setHolidayId(id);
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      await deleteHoliday(holidayId).then(() => {
        setModalDelete(false);
        fetchHolidayData();
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: any[] = [
    {
      title: () => (
        <SortFieldCustom
          title='ช่วงวัน/เวลา'
          sortDirection={sortField === "startDate" ? sortDirection : undefined}
          onSortChange={() => handleSortChange("startDate")}
        />
      ),
      dataIndex: "startDate",
      key: "startDate",
      width: "16%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Texts size='16'>
              {moment(row.startDate).format("DD/MM/YYYY, HH:mm") +
                " - " +
                moment(row.endDate).format("DD/MM/YYYY, HH:mm")}
            </Texts>
          ),
        };
      },
    },
    {
      title: "ชื่อวันหยุด",
      dataIndex: "holidayName",
      key: "holidayName",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Texts size='16'>{value}</Texts>,
        };
      },
    },

    {
      title: "ประเภท",
      dataIndex: "holidaysType",
      key: "holidaysType",
      render: (value: any, row: any, index: number) => {
        return {
          children: <Texts size='16'>{value === "INTERVAL" ? "ทำซ้ำ" : "ใช้ครั้งเดียว"}</Texts>,
        };
      },
    },
    {
      title: "สถานะ",
      dataIndex: "status",
      key: "status",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <StatusBadge
              label={value === "ACTIVE" ? "เปิดใช้งาน" : "ปิดใช้งาน"}
              bgColor={value === "ACTIVE" ? color.success : color.Text3}
            />
          ),
        };
      },
    },
    {
      title: "จัดการ",
      dataIndex: "action",
      key: "action",
      width: "12%",
      render: (value: any, row: any, index: number) => {
        return {
          children: (
            <Row justify={"start"} gutter={6}>
              <Col span={10}>
                <Button
                  onClick={() => {
                    setAddSpacialHoliday(true);
                    setEdit(true);
                    setHolidayId(row.id);
                  }}
                  typeButton='secondary'
                  icon={<img src={icon.iconEdit} style={{ width: "18px", height: "18px" }} />}
                />
              </Col>
              <Col span={10}>
                <Button
                  onClick={() => handleDeleteRow(row.id, row.holidayName)}
                  typeButton='secondary'
                  icon={<img src={icon.delete} style={{ width: "16px", height: "18px" }} />}
                />
              </Col>
            </Row>
          ),
        };
      },
    },
  ];
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: 22 }}>
        <Texts>
          อัปเดทล่าสุดเมื่อ {moment(data.latestUpdate.updatedAt).format("DD/MM/YYYY, HH:mm")}{" "}
          โดย&nbsp;
          {data.latestUpdate.updatedBy}
        </Texts>
        <u style={{ display: "flex", color: color.primary }} onClick={() => setModalHowto(true)}>
          <InfoCircleFilled style={{ color: color.primary, marginRight: 4 }} />
          <Texts style={{ color: color.primary, fontWeight: 700, cursor: "pointer" }}>
            อ่านวิธีการใช้งานเบื้องต้น
          </Texts>
        </u>
      </div>
      <div style={{ marginTop: "22px" }}>
        <Row justify='space-between' gutter={{ xs: 8 }} style={{ marginBottom: "32px" }}>
          <Col className='gutter-row' span={8}>
            <Input
              placeholder='ค้นหาชื่อวันหยุด'
              suffix={<SearchOutlined />}
              defaultValue={searchText}
              onPressEnter={(e) => {
                const value = (e.target as HTMLTextAreaElement).value;
                setSearchText(value);
                setPage(1);
              }}
              onChange={(e) => {
                const value = (e.target as HTMLInputElement).value;
                if (!value) {
                  setSearchText(undefined);
                  setPage(1);
                }
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <RangePicker
              allowEmpty={[true, true]}
              enablePast
              value={dateFilter}
              onChange={(dates, dateString) => {
                setDateFilter(dates);
                setPage(1);
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Select
              allowClear
              placeholder='ประเภททั้งหมด'
              data={[
                { key: "INTERVAL", value: "INTERVAL", label: "ทำซ้ำ" },
                { key: "ONCE", value: "ONCE", label: "ใช้ครั้งเดียว" },
              ]}
              value={type}
              style={{ width: "100%" }}
              onChange={(e) => {
                setType(e);
                setPage(1);
              }}
            />
          </Col>
          <Col className='gutter-row' span={4}>
            <Select
              value={status}
              allowClear
              placeholder='สถานะ'
              data={[
                { key: "ACTIVE", value: "ACTIVE", label: "เปิดใช้งาน" },
                { key: "INACTIVE", value: "INACTIVE", label: "ปิดใช้งาน" },
              ]}
              style={{
                width: "100%",
                fontFamily: "Sarabun",
              }}
              onChange={(e) => {
                setStatus(e);
                setPage(1);
              }}
            />
          </Col>
          <Col className='gutter-row' span={3}>
            <Button
              typeButton='primary'
              title='+ เพิ่มวันหยุด'
              height={40}
              onClick={() => {
                setAddSpacialHoliday(true);
                setEdit(false);
              }}
            />
          </Col>
        </Row>
      </div>

      <TablePagination
        isLoading={loading}
        columns={columns}
        data={data.data}
        locale={{
          emptyText: (
            <div
              style={{
                padding: 32,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 24,
                minHeight: 400,
              }}
            >
              <img
                src={image.emptyTable}
                style={{
                  width: 170,
                  height: 90,
                }}
                alt='empty_table'
              />
              <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
                ยังไม่พบข้อมูล
              </Text>
            </div>
          ),
        }}
        pagination={{
          current: page,
          total: data?.count || 0,
          pageSize: 10,
        }}
        onPageChange={(pageNum) => setPage(pageNum)}
      />
      <ConfirmModal
        visible={modalDelete}
        onConfirm={handleDeleteConfirm}
        onCancel={() => {
          setModalDelete(false);
        }}
        title='ต้องการลบวันหยุดโรงงานใช่หรือไม่?'
        desc={`โปรดยืนยันการลบวันหยุดโรงงาน "${isDays}"`}
      />

      <ManageSpecialHoliday
        isEdit={edit}
        isHolidayId={holidayId}
        visible={addSpacialHoliday}
        onCancel={() => setAddSpacialHoliday(false)}
        fetchHolidayData={() => {
          window.location.reload();
          fetchHolidayData();
        }}
      />
      <HowtoSetting
        visible={modalHowto}
        onCancel={() => setModalHowto(false)}
        title='กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ)'
        desc={getDescription("กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ)")}
      />
    </>
  );
}

export default SpecialHoliday;
