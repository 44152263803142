export default {
  login: require("./media/images/bgLoginSellcoda.png"),
  error404: require("./media/images/404.png"),
  no_image: require("./media/images/no_image.png"),
  product_no_image: require("./media/images/product_no_img.jpg"),
  emptyTable: require("./media/images/emptyTable.png"),
  icp_ladda: require("./media/images/LogoICPLadda.png"),
  icp_international: require("./media/images/LogoICPInter.png"),
  icp_fertilizer: require("./media/images/LogoICPFert.png"),
  product_box: require("./media/images/product-box.png"),
  empty_shop: require("./media/images/empty_shop.png"),
  emptyPromotion: require("./media/images/default_promotion.jpg"),
  emptyProFreebie: require("./media/images/IconEmtryStateOnFreebie.png"),
  indexShopApp: require("./media/images/LaddaShopApp-Homepage.png"),
  expPromotion: require("./media/images/expPromotion.png"),
  detailPromotion: require("./media/images/LaddaShopApp-PromotionDetail.png"),
  detailPromotionCard: require("./media/images/proDetailCard.png"),
  iconSaleApp: require("./media/images/iconSaleApp.png"),
  iconShopApp: require("./media/images/iconShopApp.png"),
  expHighLight: require("./media/images/expHighLight.png"),
  indexHighLightApp: require("./media/images/indexHighLightApp.png"),
  emptyProductBrand: require("./media/images/EmptyState-ProductBrand.jpg"),
  emptyTableBrand: require("./media/images/EmtryTableBrand.png"),
  imageLogin: require("./media/images/imageLogin.png"),
  emptyLineChart: require("./media/images/emptylinegraph.png"),
  emptyPie: require("./media/images/emptypie.png"),
  emptyPromotionReport: require("./media/images/emptypromotion.png"),
  sellcodaLogo: require("./media/images/sellcoda-logo.png"),
  loginError: require("./media/images/loginError.png"),
  infoIcon: require("./media/images/Info.png"),
  conditionIcon: require("./media/images/conditionIcon.png"),
  campaignDetail: require("./media/images/campaignDetail.png"),
  pdfIcon: require("./media/images/PdfIcon.png"),
  empty_reportCRM: require("./media/images/empty_reportCRM.png"),
  emptyShopTable: require("./media/images/emptyShopTable.png"),
  addProduct: require("./media/images/add-product.png"),
  errorState: require("./media/images/ErrorState.png"),
  warning_product_quota: require("./media/images/warning_product_quota.png"),
};
