import { Col, Divider, Modal, Row } from "antd";
import React, { type JSX } from "react";
import styled from "styled-components";
import Text from "../../../../components/Text/Text";
import { InfoCircleFilled } from "@ant-design/icons";
import color from "../../../../resource/color";
import Button from "../../../../components/Button/Button";
import { icon } from "../../../../resource";

const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-body {
    padding: 0;
    text-align: center;
  }
`;

interface Props {
  title?: string;
  visible?: boolean;
  onCancel?: () => void;
  desc?: any;
}

export const getDescription = (title: string): JSX.Element => {
  switch (title) {
    case "เวลาเปิด/ปิดคำสั่งซื้อ (ร้านทั้งหมด)":
      return (
        <>
          เวลาเปิด/ปิดคำสั่งซื้อ (ร้านทั้งหมด) จะเป็นการกำหนดเวลาที่{" "}
          <Text fontWeight={700}>“ร้านค้าทุกร้าน”</Text>
          จะสามารถสั่งซื้อและไม่สามารถสั่งซื้อได้ โดยมีข้อแนะนำการตั้งค่าดังนี้
        </>
      );
    case "เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน)":
      return (
        <>
          เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน) จะเป็นการกำหนดเวลาที่{" "}
          <Text fontWeight={700}>“ร้านพิเศษ”</Text>
          จะสามารถสั่งซื้อและไม่สามารถสั่งซื้อได้ ซึ่งจะไม่เกี่ยวข้องกับเวลาเปิด/ปิดคำสั่งซื้อ
          (ร้านทั้งหมด)
        </>
      );
    case "กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ)":
      return (
        <>
          กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ) จะเป็นการกำหนดวันและเวลาที่เป็น{" "}
          <Text fontWeight={700}>“วันหยุด””</Text>
          โดยการสั่งซื้อของร้านทั้งหมดในระบบจะไม่สามารถสั่งซื้อได้
        </>
      );

    default:
      return <></>;
  }
};

const HowtoSetting: React.FC<Props> = ({ title = "", visible, onCancel, desc }) => {
  const description = desc ?? getDescription(title);
  const isSpecialStore = title === "เวลาเปิด-ปิดการสั่งซื้อ (เฉพาะร้าน)";
  const isSpecialFactory = title === "กำหนดวันหยุดโรงงาน (ปิดรับคำสั่งซื้อ)";

  return (
    <ModalStyled
      open={visible}
      closeIcon={false}
      onCancel={onCancel}
      centered
      footer={null}
      width={583}
    >
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <InfoCircleFilled style={{ color: color.primary, fontSize: 30 }} />
        </div>

        <Text fontFamily='IBM Plex Sans Thai' fontSize={20} fontWeight={700}>
          วิธีการใช้งาน
        </Text>
        <br />
        <Text fontFamily='IBM Plex Sans Thai' fontSize={20} fontWeight={700}>
          &#8220;{title}&#8221;
        </Text>
        <br />
        <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
          {description}
        </Text>

        {isSpecialStore && (
          <div style={{ marginTop: 12 }}>
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700}>
              ตัวอย่างเช่น
            </Text>
            <br />
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              - ร้านทั้งหมด ตั้งค่าให้สั่งซื้อได้ จันทร์-ศุกร์ เต็มวัน | ปิดการสั่งซื้อ เสาร์
              อาทิตย์
            </Text>
            <br />
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              - ร้านพิเศษ ตั้งค่าให้สั่งซื้อได้ จันทร์-อาทิตย์ เต็มวัน ได้
            </Text>
          </div>
        )}
        {isSpecialFactory && (
          <div style={{ marginTop: 12 }}>
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700}>
              โดยจะแบ่งประเภทการตั้งค่าวันหยุดเป็น 2 ประเภท
            </Text>
            &nbsp;
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              ได้แก่
            </Text>
            <br />
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              1. <Text fontWeight={700}>ทำซ้ำ</Text> &nbsp;จะเป็นวันหยุดตามปฏิทิน
              สามารถสร้างและใช้ซ้ำได้ เช่น วันหยุดปีใหม่ หลังจากสร้างแล้ว ผ่านวันหยุดแล้ว
              ไม่จำเป็นต้องสร้างใหม่ ระบบทำซ้ำในปีต่อไปให้
            </Text>
            <br />
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              2. <Text fontWeight={700}>ใช้ครั้งเดียว</Text> &nbsp; จะเป็นหยุดพิเศษ
              หรือกรณีพิเศษที่ใช้กัน เช่น โรงงานปิดปรับปรุงชั่วคราว โดยจะเป็นสร้างแล้ว หยุดแล้ว จบไป
            </Text>
          </div>
        )}

        <Divider />
        {!isSpecialFactory && (
          <Row gutter={[16, 16]} style={{ marginBottom: 6 }}>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <img
                src={icon.checkPrimary}
                style={{ width: 18, height: 18, marginRight: 8 }}
                alt='Check Icon'
              />
              <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
                กดปุ่มนี้เพื่อเปิดให้สามารถสั่งซื้อได้
              </Text>
            </Col>
            <Col
              span={12}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              <Row style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    border: "solid 0.2px rgb(141, 148, 163)",
                    width: 18,
                    height: 18,
                    borderRadius: "20%",
                    marginRight: 8,
                  }}
                />
                <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
                  กดปุ่มนี้เพื่อปิดไม่ให้สามารถสั่งซื้อได้
                </Text>
              </Row>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]} style={{ marginBottom: 6 }}>
          <img src={icon.switchIcon} style={{ width: 42, height: 26, marginRight: 8 }} />
          <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700}>
            เต็มวัน
          </Text>
          &nbsp;
          <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
            คือการกำหนดเวลาเปิดการสั่งซื้อ 24 ชั่วโมง (00:00 - 23:59)
          </Text>
        </Row>

        <Row gutter={[16, 16]} style={{ marginBottom: 6 }}>
          <Col span={2}>
            <img src={icon.timerIcon} style={{ width: 38, height: 23, marginRight: 6 }} />
          </Col>
          <Col span={22}>
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={700}>
              กำหนดเวลาเอง
            </Text>
            &nbsp;
            <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400}>
              คือ กำหนดเวลาเปิดการสั่งซื้อเริ่มต้น - สิ้นสุดเอง (ตัวอย่างเช่น 08:30 - 17:30)
              และสามารถแบ่งช่วงเวลาได้ 2 ช่วงเวลา
            </Text>
          </Col>
        </Row>

        <Divider />

        <Text fontFamily='IBM Plex Sans Thai' fontSize={16} fontWeight={400} color='error'>
          การตั้งค่าควรจะมีการปรึกษาและหารือระหว่างทีมต่างๆ ในบริษัทให้เรียบร้อยก่อนบันทึก
          เนื่องจากมีผลต่อการสั่งซื้อ และความรู้สึกของร้านค้าโดยตรง
        </Text>

        <div style={{ marginTop: 14 }}>
          <Button title='ตกลง' typeButton='primary' onClick={onCancel} />
        </div>
      </div>
    </ModalStyled>
  );
};

export default HowtoSetting;
