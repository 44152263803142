import React, { useState, useEffect, useCallback } from "react";
import { Col, Form, message, Modal, Radio, Row, Select, Space, Spin } from "antd";
import styled from "styled-components";
import dayjs, { type Dayjs } from "dayjs";

import Text from "../../../../components/Text/Text";
import Button from "../../../../components/Button/Button";
import DayTimePeriod from "../ShareComponent/DayTimePeriod";

import { Texts } from "../OrderPeriod.styles";
import { defaultOrderTimeState } from "../../../../definitions/dateInfo";
import { getCusCorporate } from "../../../../datasource/CustomerDatasource";
import {
  createOrderTimeShop,
  getOrderTimeById,
  updateOrderTimeShop,
} from "../../../../datasource/OrderTimeDatasource";
import {
  OrderTimeMasterEntity,
  OrderTimeMasterEntity_INIT,
} from "../../../../entities/OrderTimeEntity";
import color from "../../../../resource/color";
import { type size, type take, debounce, entries, keys, map, times } from "lodash";
import { checkTimeRangeOverlap } from "../../../../definitions/checkTimeOverlap";

const StyledSelect = styled(Select)`
  .ant-select-selection-search-input,
  .ant-select-selection-placeholder,
  .ant-select-selection-item {
    font-family: Sarabun !important;
    font-size: 14px;
  }
`;

const ModalStyled = styled(Modal)`
  .ant-modal-body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

interface Props {
  visible: boolean;
  onCancel: () => void;
  shopOrderId: string;
  newFetch: () => void;
  isEdit: boolean;
}

const ManageSpecialShop: React.FC<Props> = ({
  visible,
  onCancel,
  isEdit,
  shopOrderId,
  newFetch,
}) => {
  const [form] = Form.useForm();
  const company = JSON.parse(localStorage.getItem("profile")!);
  const companyCode = JSON.parse(localStorage.getItem("company")!);

  const [shopList, setShopList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectShop, setSelectShop] = useState<any>(null);
  const [orderTimeState, setOrderTimeState] = useState<OrderTimeMasterEntity>(
    OrderTimeMasterEntity_INIT,
  );
  const [createOrderTimeState, setCreateOrderTimeState] =
    useState<OrderTimeMasterEntity>(defaultOrderTimeState);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [shopState, setShopState] = useState<{
    dealerName: string;
    dealerId: string;
    dealerCode: string;
    dealerNo: string;
  }>({
    dealerName: "",
    dealerId: "",
    dealerCode: "",
    dealerNo: "",
  });
  const [status, setStatus] = useState<string>("");

  const [componentKey, setComponentKey] = useState(0);

  const handleClose = () => {
    form.resetFields();
    if (!isEdit) {
      setSelectShop(null);
      setOrderTimeState(OrderTimeMasterEntity_INIT);
      setCreateOrderTimeState(defaultOrderTimeState);
    }
    onCancel();
    setComponentKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (visible) {
      if (isEdit && shopOrderId) {
        fetchOrderTimeById(shopOrderId);
      }
    }
  }, [visible, isEdit, shopOrderId]);

  const fetchOrderTimeById = async (id: string) => {
    try {
      const res = await getOrderTimeById(id);
      form.setFieldsValue({ shopName: res.dealerName });
      setStatus(res.status);
      setShopState({
        dealerName: res.dealerName,
        dealerId: res.dealerId,
        dealerCode: res.dealerCode,
        dealerNo: res.dealerNo,
      });

      const newOrderTimeState = res.data.reduce((acc, item) => {
        acc[item.day] = {
          fullDay: item.fullDay,
          isClose: item.isClose,
          times: item.times.map((time) => ({
            startTime: dayjs(time.start),
            endTime: dayjs(time.end),
          })),
        };
        return acc;
      }, {} as OrderTimeMasterEntity);

      setOrderTimeState(newOrderTimeState);
    } catch (error) {
      console.error("Error fetching order time data:", error);
    }
  };
  const fetchShopList = useCallback(
    async (pageNumber: number) => {
      if (loading || !hasMore) return;
      setLoading(true);

      try {
        const response = await getCusCorporate({
          page: pageNumber,
          take: 10,
          companyCode: companyCode.companyCode,
          sortField: "updateDate",
          sortDirection: "DESC",
          search: searchTerm,
          isActive: true,
          customerType: "DL",
        });

        const newData = response.data.map((item: any, index: number) => ({
          label: item.customerName,
          value: item.customerName,
          key: `${item.customerCompanyId}-${index}`,
          taxNo: item.customer.taxNo,
          dealerCode: item.customerNo,
        }));

        setShopList((prev) => {
          const existingKeys = new Set(prev.map((shop) => shop.key));
          const uniqueNewData = newData.filter((shop) => !existingKeys.has(shop.key));
          return [...prev, ...uniqueNewData];
        });

        setHasMore(newData.length === 10);
      } catch (error) {
        console.error("Error fetching shop list:", error);
      } finally {
        setLoading(false);
      }
    },
    [loading, hasMore, companyCode.companyCode, searchTerm],
  );

  useEffect(() => {
    fetchShopList(1);
  }, [searchTerm]);

  const handleSearch = useCallback(
    debounce((value) => {
      setSearchTerm(value);
      setShopList([]);
      setPageNumber(1);
      setHasMore(true);
    }, 300),
    [],
  );
  const updateTimePeriods = useCallback(
    (day: string, index: number, timeType: "startTime" | "endTime", value: string) => {
      const updateState = (prevState: any) => {
        const updatedTimePeriods = [...prevState[day].times];
        updatedTimePeriods[index][timeType] = value;

        return {
          ...prevState,
          [day]: { ...prevState[day], times: updatedTimePeriods },
        };
      };
      isEdit ? setOrderTimeState(updateState) : setCreateOrderTimeState(updateState);
    },
    [isEdit],
  );

  const handleAddTimePeriod = useCallback(
    (day: string) => {
      isEdit
        ? setOrderTimeState((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              times: [...prev[day].times, { startTime: "", endTime: "" }],
            },
          }))
        : setCreateOrderTimeState((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              times: [...prev[day].times, { startTime: "", endTime: "" }],
            },
          }));
    },
    [isEdit],
  );
  const handleRemoveTimePeriod = useCallback(
    (day: string) => {
      isEdit
        ? setOrderTimeState((prev) => {
            const updatedTimePeriods = prev[day].times.slice(0, -1);
            return {
              ...prev,
              [day]: { ...prev[day], times: updatedTimePeriods },
            };
          })
        : setCreateOrderTimeState((prev) => {
            const updatedTimePeriods = prev[day].times.slice(0, -1);
            return {
              ...prev,
              [day]: { ...prev[day], times: updatedTimePeriods },
            };
          });
    },
    [isEdit],
  );
  const handleSwitch = (day: string, checked: boolean) => {
    isEdit
      ? setOrderTimeState((prevState) => ({
          ...prevState,
          [day]: {
            ...prevState[day],
            fullDay: checked,
            times: checked
              ? [
                  {
                    startTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    endTime: new Date(new Date().setHours(23, 59, 59, 0)).toISOString(),
                  },
                ]
              : [
                  {
                    startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                    endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
                  },
                ],
          },
        }))
      : setCreateOrderTimeState((prevState) => ({
          ...prevState,
          [day]: {
            ...prevState[day],
            fullDay: checked,
            times: checked
              ? [
                  {
                    startTime: new Date(new Date().setHours(0, 0, 0, 0)).toISOString(),
                    endTime: new Date(new Date().setHours(23, 59, 59, 0)).toISOString(),
                  },
                ]
              : [
                  {
                    startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                    endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
                  },
                ],
          },
        }));
  };
  const handleCheckboxChange = useCallback(
    (day: string, isChecked: boolean) => {
      isEdit
        ? setOrderTimeState((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              isClose: !isChecked,
              times: !isChecked
                ? []
                : [
                    {
                      startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                      endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
                    },
                  ],
            },
          }))
        : setCreateOrderTimeState((prev) => ({
            ...prev,
            [day]: {
              ...prev[day],
              isClose: !isChecked,
              times: !isChecked
                ? []
                : [
                    {
                      startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
                      endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
                    },
                  ],
            },
          }));
    },
    [isEdit],
  );
  const timeState = isEdit ? orderTimeState : createOrderTimeState;

  const validateOrderTimeData = () => {
    for (const day of Object.keys(timeState)) {
      const { fullDay, times, isClose } = timeState[day];
      if (!fullDay && !isClose) {
        if (times.length === 0) {
          message.error("กรุณากำหนดช่วงเวลา");
          return false;
        }
        for (const time of times) {
          if (!time.startTime || !time.endTime) {
            message.error("กรุณากำหนดเวลาเริ่มต้นและเวลาสิ้นสุดให้ครบถ้วน");
            return false;
          }

          if (time.startTime >= time.endTime) {
            message.error("ช่วงเวลาทับซ้อนกัน");
            return false;
          }
        }
      }
    }
    return true;
  };

  const validateNoOverlappingTimes = () => {
    for (const day of Object.keys(orderTimeState)) {
      const times = orderTimeState[day]?.times || [];
      for (let i = 0; i < times.length; i++) {
        for (let j = i + 1; j < times.length; j++) {
          const { startTime: startA, endTime: endA } = times[i] || {};
          const { startTime: startB, endTime: endB } = times[j] || {};
          if (!startA || !endA || !startB || !endB) continue;
          const startTimeA = dayjs(startA).format("HH:mm");
          const endTimeA = dayjs(endA).format("HH:mm");
          const startTimeB = dayjs(startB).format("HH:mm");
          const endTimeB = dayjs(endB).format("HH:mm");

          if (checkTimeRangeOverlap(startTimeA, endTimeA, startTimeB, endTimeB)) {
            message.error("มีช่วงเวลาที่ทับซ้อนกัน");
            return false;
          }
        }
      }
    }
    return true;
  };

  const validateData = () => {
    return validateOrderTimeData() && validateNoOverlappingTimes();
  };

  const handleFinish = async () => {
    if (!validateData()) return;

    setLoading(true);
    const formattedData = {
      dealerId: shopState.dealerId || selectShop.dealerId,
      dealerName: shopState.dealerName || selectShop.dealerName,
      dealerCode: shopState.dealerCode || selectShop.dealerCode,
      dealerNo: shopState.dealerNo || selectShop.dealerNo,
      status: status || "ACTIVE",
      data: Object.entries(timeState).map(([day, { fullDay, isClose, times }]) => {
        const updatedFullDay = isClose ? false : fullDay;
        return {
          day,
          fullDay: updatedFullDay,
          isClose,
          times: (times ?? []).map(({ startTime, endTime }) => ({
            start: startTime,
            end: endTime,
          })),
        };
      }),
      createdBy: `${company?.firstname} ${company?.lastname}`,
      updatedBy: `${company?.firstname} ${company?.lastname}`,
      company: company?.company,
    };

    try {
      let res;
      if (isEdit && shopOrderId) {
        res = await updateOrderTimeShop(shopOrderId, formattedData);
      } else {
        res = await createOrderTimeShop(formattedData);
      }

      if (res.error && res.message === "พบ dealer ซ้ำในระบบ") {
        form.setFields([{ name: "shopName", errors: ["พบ dealer ซ้ำในระบบ"] }]);
        return;
      }

      newFetch();
      handleClose();
    } catch (error) {
      console.error("Error saving order time data:", error);
      message.error("ช่วงเวลาที่กำหนดมีการทับซ้อนกัน");
    } finally {
      setLoading(false);
    }
  };

  return (
    <ModalStyled
      key={componentKey}
      open={visible}
      onCancel={handleClose}
      centered
      width={1350}
      footer={null}
      styles={{
        body: {
          padding: "20px",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "calc(90vh - 56px)",
        },
      }}
    >
      <Text fontSize={20} fontWeight={700}>
        {isEdit ? "แก้ไข" : "เพิ่ม"}ร้านค้า
      </Text>
      <Form form={form} layout='vertical' onFinish={handleFinish} style={{ marginTop: "8px" }}>
        <Texts>
          ชื่อร้านค้า <span style={{ color: color.error }}>*</span>
        </Texts>
        <Form.Item name='shopName' rules={[{ required: true, message: "กรุณาเลือกชื่อร้านค้า" }]}>
          <StyledSelect
            allowClear
            disabled={isEdit ? true : false}
            style={{ marginBottom: 0, width: "100%", height: "40px" }}
            placeholder='ระบุชื่อร้านค้า'
            options={shopList}
            showSearch
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            onSearch={handleSearch}
            onPopupScroll={(e) => {
              const target = e.target as HTMLElement;
              if (
                target.scrollTop + target.clientHeight >= target.scrollHeight - 20 &&
                hasMore &&
                !loading
              ) {
                fetchShopList(pageNumber + 1);
                setPageNumber((prev) => prev + 1);
              }
            }}
            notFoundContent={loading ? <Spin size='small' /> : "ไม่มีข้อมูล"}
            onChange={(value, option: any) => {
              if (option) {
                setSelectShop({
                  dealerName: option.label,
                  dealerId: option.key.split("-")[0],
                  dealerCode: option.dealerCode,
                  dealerNo: option.taxNo,
                });
              }
            }}
          />
        </Form.Item>
      </Form>
      <div>
        {Object.entries(timeState).map(([day, config]) => (
          <DayTimePeriod
            key={day}
            day={day}
            checkedDays={config.isClose}
            times={config.times}
            isFullDay={config.fullDay}
            isPeriodAdded={(config.times ?? []).length > 1}
            onChangeSwitch={handleSwitch}
            onChangeStartTime={(date, index, value: any) =>
              updateTimePeriods(day, index, "startTime", value)
            }
            onChangeEndTime={(date, index, value: any) =>
              updateTimePeriods(day, index, "endTime", value)
            }
            handleAddTimePeriod={() => handleAddTimePeriod(day)}
            handleRemoveTimePeriod={() => handleRemoveTimePeriod(day)}
            handleCheckboxChange={handleCheckboxChange}
          />
        ))}
      </div>

      {isEdit && (
        <div>
          <Texts>
            สถานะ <span style={{ color: color.error }}>*</span>
          </Texts>
          <Radio.Group onChange={(e) => setStatus(e.target.value)} value={status}>
            <Space direction='vertical'>
              <Radio value='ACTIVE'>
                <Texts>เปิดใช้งาน</Texts>
              </Radio>
              <Radio value='INACTIVE'>
                <Texts>ปิดการใช้งาน</Texts>
              </Radio>
            </Space>
          </Radio.Group>
        </div>
      )}

      <Row justify='space-between' style={{ marginTop: 22 }}>
        <Col>
          <Button typeButton='border-primary' onClick={handleClose} title='ยกเลิก' />
        </Col>
        <Col>
          <Button typeButton='primary' onClick={() => form.submit()} title='บันทึก' />
        </Col>
      </Row>
    </ModalStyled>
  );
};

export default ManageSpecialShop;
