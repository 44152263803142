import { Col, Form, Modal, Row, Switch, Button, Radio, Space, type message, Select } from "antd";
import React, { useState, useCallback, useEffect } from "react";
import Text from "../../../../components/Text/Text";
import Input from "../../../../components/Input/Input";
import { Texts } from "../OrderPeriod.styles";
import DatePickerSection from "../ShareComponent/DatePickerSection";
import TimePickerSection from "../ShareComponent/TimePickerSection";
import dayjs, { Dayjs } from "dayjs";
import {
  checkHolidayTime,
  createHolidays,
  getHolidaysById,
  updateHolidays,
} from "../../../../datasource/OrderTimeDatasource";
import styled from "styled-components";
import { color } from "../../../../resource";

const SelectCustom = styled(Select)`
  .ant-select-selector {
    height: 100% !important;
    min-height: 40px !important;
    display: flex;
    align-items: center;
    font-family: Sarabun !important;
  }
  .ant-select-selection-placeholder {
    font-family: Sarabun !important;
  }

  .ant-select-selection-item {
    border-radius: 4px;
    font-family: Sarabun !important;
    font-weight: 400 !important;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;
interface Props {
  visible: boolean;
  onCancel: () => void;
  isEdit: boolean;
  isHolidayId: string;
  fetchHolidayData: () => void;
}

function ManageSpecialHoliday({ visible, onCancel, isEdit, isHolidayId, fetchHolidayData }: Props) {
  const [form] = Form.useForm();
  const [type, setType] = useState<string | undefined>();
  const [isFullDayChecked, setIsFullDayChecked] = useState<boolean>(true);
  const userProfile = JSON.parse(localStorage.getItem("profile")!);
  const [loading, setLoading] = useState(false);
  const [timeDup, setTimeDup] = useState(false);
  const [startTime, setStartTime] = useState<Dayjs | null>(null);
  const [endTime, setEndTime] = useState<Dayjs | null>(null);

  useEffect(() => {
    if (visible) {
      if (isEdit && isHolidayId) {
        HolidayById();
      } else {
        form.resetFields();
      }
    }
  }, [visible, isEdit, isHolidayId]);

  const HolidayById = async () => {
    setLoading(true);
    try {
      const res = await getHolidaysById(isHolidayId);
      setIsFullDayChecked(res.fullDay);
      form.setFieldsValue({
        holidayName: res.holidayName,
        startDate: dayjs(res.startDate),
        endDate: dayjs(res.endDate),
        startTime: dayjs(res.startDate),
        endTime: dayjs(res.endDate),
        type: res.holidaysType,
        status: res.status,
      });
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchChange = (checked: boolean) => {
    setIsFullDayChecked(checked);
    setTimeDup(false);

    if (checked) {
      form.setFieldsValue({ startTime: undefined, endTime: undefined });
    } else {
      form.setFieldsValue({
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
      });
    }
  };
  useEffect(() => {
    const { startDate, endDate } = form.getFieldsValue(["startDate", "endDate"]);
    if (startDate && endDate) {
      checkTime();
    }
  }, [form]);

  const handleClose = () => {
    form.resetFields();
    setTimeDup(false);
    setIsFullDayChecked(true);
    onCancel();
  };

  const checkTime = async (): Promise<boolean> => {
    const valueDate = form.getFieldsValue();
    if (!valueDate.startDate || !valueDate.endDate) {
      return false;
    }

    try {
      const object: any = {
        startDate: valueDate.startDate.toISOString(),
        endDate: valueDate.endDate.toISOString(),
        company: userProfile?.company,
        ...(isEdit && { id: isHolidayId }),
      };
      const res = await checkHolidayTime(object);

      if (res?.multiple) {
        setTimeDup(true);
        return false;
      }

      setTimeDup(false);
      return true;
    } catch (error) {
      console.error("Error checking holiday time:", error);
      return false;
    }
  };
  const handleTimeChange = (field: "startDate" | "endDate", time: Dayjs | null) => {
    form.setFieldsValue({ [field]: time });
    const startDate = form.getFieldValue("startDate");
    const endDate = form.getFieldValue("endDate");
    if (startDate && endDate) {
      checkTime();
    }
  };

  const handleFinish = useCallback(
    async (values: any) => {
      const formattedData = {
        holidayName: values.holidayName,
        startDate: isFullDayChecked
          ? values.startDate.startOf("day").toISOString()
          : dayjs(
              `${values.startDate?.format("YYYY-MM-DD")} ${values.startTime?.format("HH:mm")}:00.000`,
            ).toISOString(),
        endDate: isFullDayChecked
          ? values.endDate.endOf("day").toISOString()
          : dayjs(
              `${values.endDate?.format("YYYY-MM-DD")} ${values.endTime?.format("HH:mm")}:00.000`,
            ).toISOString(),
        holidaysType: values.type,
        status: values.status || "ACTIVE",
        updatedBy: `${userProfile?.firstname} ${userProfile?.lastname}`,
        fullDay: isFullDayChecked,
        company: userProfile?.company,
      };
      try {
        if (isEdit) {
          updateHolidays(isHolidayId, formattedData);
        } else {
          createHolidays(formattedData);
        }
        fetchHolidayData();
        onCancel();
      } catch (error) {
        console.log(error);
      }
    },
    [isFullDayChecked, fetchHolidayData],
  );

  return (
    <Modal
      open={visible}
      onCancel={handleClose}
      centered
      width={1000}
      footer={null}
      confirmLoading={loading}
    >
      <Text fontFamily='IBM Plex Sans Thai' fontSize={20} fontWeight={700} color='Text2'>
        {isEdit ? "แก้ไข" : "เพิ่ม"}วันหยุด
      </Text>
      <Form form={form} layout='vertical' onFinish={handleFinish} style={{ marginTop: "22px" }}>
        <Form.Item
          label='ชื่อวันหยุด'
          name='holidayName'
          rules={[{ required: true, message: "กรุณากรอกชื่อวันหยุด" }]}
        >
          <Input placeholder='ระบุชื่อวันหยุด' autoComplete='off' />
        </Form.Item>

        <Row gutter={16} justify='space-between'>
          <Col style={{ marginTop: "10px" }}>
            <Texts>เต็มวัน</Texts>
            <Switch checked={isFullDayChecked} onChange={handleSwitchChange} />
          </Col>
          <Col span={!isFullDayChecked ? 5 : 11}>
            <Form.Item name='startDate'>
              <DatePickerSection
                borderColor={timeDup ? color.error : "none"}
                name='startDate'
                placeholder='เลือกวันที่เริ่มต้น'
                label='วันที่เริ่มต้น'
                onChange={(date: Dayjs | null, dateString: string | string[]) => {
                  form.setFieldsValue({ startDate: date });
                  handleTimeChange("startDate", date);
                }}
                err={timeDup}
                errText='ไม่สามารถใช้ช่วงนี้ได้ เนื่องจากช่วงวันที่นี้ถูกใช้งานแล้ว'
                span={24}
              />
            </Form.Item>
          </Col>
          {!isFullDayChecked && (
            <Col span={6}>
              <Form.Item
                name='startTime'
                rules={[{ required: true, message: "กรุณาเลือกเวลาเริ่มต้น" }]}
              >
                <TimePickerSection
                  name='startTime'
                  label='เวลาเริ่มต้น'
                  onChange={(time) => {
                    setStartTime(time);
                    form.setFieldsValue({ startTime: time });
                  }}
                  span={24}
                  endTime={dayjs(endTime)}
                />
              </Form.Item>
            </Col>
          )}

          <Col span={!isFullDayChecked ? 5 : 11}>
            <Form.Item name='endDate'>
              <DatePickerSection
                err={timeDup}
                borderColor={timeDup ? color.error : "none"}
                name='endDate'
                placeholder='เลือกวันที่สิ้นสุด'
                label='วันที่สิ้นสุด'
                onChange={(date: Dayjs | null, dateString: string | string[]) => {
                  form.setFieldsValue({ endDate: date });
                  handleTimeChange("endDate", date);
                }}
                span={24}
                disabledDate={(current: Dayjs) => {
                  const startDate = form.getFieldValue("startDate");
                  return current && current.isBefore(dayjs(startDate));
                }}
              />
            </Form.Item>
          </Col>

          {!isFullDayChecked && (
            <Col span={6}>
              <Form.Item
                name='endTime'
                rules={[{ required: true, message: "กรุณาเลือกเวลาสิ้นสุด" }]}
              >
                <TimePickerSection
                  name='endTime'
                  label='เวลาสิ้นสุด'
                  onChange={(time) => {
                    setEndTime(time);
                    form.setFieldsValue({ endTime: time });
                  }}
                  span={24}
                  startTime={dayjs(startTime)}
                />
              </Form.Item>
            </Col>
          )}
        </Row>
        <Form.Item
          label='ประเภทวันหยุด'
          name='type'
          rules={[{ required: true, message: "กรุณาเลือกประเภทวันหยุด" }]}
        >
          <SelectCustom
            allowClear
            placeholder='เลือกประเภทวันหยุด'
            options={[
              { key: "INTERVAL", value: "INTERVAL", label: "ทำซ้ำ" },
              { key: "ONCE", value: "ONCE", label: "ใช้ครั้งเดียว" },
            ]}
            value={type}
            onChange={(e: any) => setType(e)}
          />
        </Form.Item>
        {isEdit && (
          <Form.Item
            label='สถานะ'
            name='status'
            rules={[{ required: true, message: "กรุณาเลือกสถานะ" }]}
          >
            <Radio.Group>
              <Space direction='vertical'>
                <Radio value='ACTIVE'>
                  <Texts>เปิดใช้งาน</Texts>
                </Radio>
                <Radio value='INACTIVE'>
                  <Texts>ปิดการใช้งาน</Texts>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item style={{ marginTop: 32 }}>
          <Row justify='space-between'>
            <Col>
              <Button type='default' htmlType='reset' onClick={onCancel}>
                <Text fontSize={14} color='primary'>
                  ย้อนกลับ
                </Text>
              </Button>
            </Col>
            <Col>
              <Button type='primary' htmlType='submit'>
                <Text fontSize={14} color='white'>
                  บันทึก
                </Text>
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ManageSpecialHoliday;
