import type { AxiosResponse } from "axios";
import { BASE_URL, httpClient } from "../config/develop-config";

const getOrderTimeMaster = async (company: string) => {
  return await httpClient
    .get(BASE_URL + `/ma/order-time-master/${company}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateOrderTimeMaster = async (params: object) => {
  return await httpClient
    .patch(BASE_URL + "/ma/order-time-master", params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const createHolidays = async (params: object) => {
  return await httpClient
    .post(BASE_URL + "/ma/holidays", params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateHolidays = async (id: string, params: object) => {
  return await httpClient
    .patch(BASE_URL + `/ma/holidays/${id}`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getHolidays = async (
  page: number,
  take: number,
  company: string,
  search?: string,
  status?: string,
  startDate?: string,
  endDate?: string,
  holidaysType?: string,
  sortBy?: string,
  sortDirection?: string,
) => {
  const params = {
    page: page,
    take: take,
    company: company,
    search: search,
    status: status,
    startDate: startDate,
    endDate: endDate,
    holidaysType: holidaysType,
    sortBy: sortBy,
    sortDirection: sortDirection,
  };
  return await httpClient
    .get(BASE_URL + "/ma/holidays", { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getHolidaysById = async (id: string) => {
  return await httpClient
    .get(BASE_URL + `/ma/holidays/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const deleteHoliday = async (id: string) => {
  return await httpClient
    .delete(BASE_URL + `/ma/holidays/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getOrderTime = async (
  page: number,
  take: number,
  company: string,
  search?: string,
  status?: string,
  timeRange?: string,
) => {
  const params = {
    page: page,
    take: take,
    company: company,
    search: search,
    status: status,
    timeRange: timeRange,
  };
  return await httpClient
    .get(BASE_URL + `/ma/order-time`, { params })
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const getOrderTimeById = async (id: string) => {
  return await httpClient
    .get(BASE_URL + `/ma/order-time/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const checkHolidayTime = async (oject: object) => {
  return await httpClient
    .post(BASE_URL + `/ma/holidays/check-multiple-holiday`, oject)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const createOrderTimeShop = async (params: object) => {
  return await httpClient
    .post(BASE_URL + "/ma/order-time", params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const updateOrderTimeShop = async (id: string, params: object) => {
  return await httpClient
    .patch(BASE_URL + `/ma/order-time/${id}`, params)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
const deleteOrderTimeShop = async (id: string) => {
  return await httpClient
    .delete(BASE_URL + `/ma/order-time/${id}`)
    .then((res: AxiosResponse) => res.data)
    .catch((err) => console.log(err));
};
export {
  getOrderTimeMaster,
  updateOrderTimeMaster,
  getHolidays,
  deleteHoliday,
  createHolidays,
  getHolidaysById,
  updateHolidays,
  getOrderTime,
  checkHolidayTime,
  createOrderTimeShop,
  deleteOrderTimeShop,
  getOrderTimeById,
  updateOrderTimeShop,
};
