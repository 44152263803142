import color from "../resource/color";

export type ProductQuotaStatus = "WAITING" | "ACTIVE" | "INACTIVE" | "EXPIRED";

export const MAP_STATUS_COLOR: any = {
  WAITING: color.secondary2,
  ACTIVE: color.success,
  INACTIVE: color.placeholder,
  EXPIRED: color.placeholder,
};
export const MAP_STATUS_WORD: any = {
  WAITING: "รอเริ่ม",
  ACTIVE: "เปิดใช้งาน",
  INACTIVE: "ปิดใช้งาน",
  EXPIRED: "หมดอายุ",
};
