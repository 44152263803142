import React, { useEffect } from "react";
import type { OrderTimeMasterEntity } from "../entities/OrderTimeEntity";

export const daysOfWeek = ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"];
export const fullDaysOfWeek = [
  "วันจันทร์",
  "วันอังคาร",
  "วันพุธ",
  "วันพฤหัสบดี",
  "วันศุกร์",
  "วันเสาร์",
  "วันอาทิตย์",
];
export const MAP_DAYS: any = {
  วันอาทิตย์: "อา",
  วันจันทร์: "จ",
  วันอังคาร: "อ",
  วันพุธ: "พ",
  วันพฤหัสบดี: "พฤ",
  วันศุกร์: "ศ",
  วันเสาร์: "ส",
};

export const defaultOrderTimeState: OrderTimeMasterEntity = fullDaysOfWeek.reduce((acc, day) => {
  acc[day] = {
    fullDay: false,
    isClose: false,
    times: [
      {
        startTime: new Date(new Date().setHours(9, 0, 0, 0)).toISOString(),
        endTime: new Date(new Date().setHours(18, 0, 0, 0)).toISOString(),
      },
    ],
  };
  return acc;
}, {} as OrderTimeMasterEntity);
